import React from 'react'
import { NavLink } from 'react-router-dom'
import "./Testimonial.css"
import Navbar from './Navbar'
import Silder from './Silder'

const Testimonial = () => {
    return (
        <>

            <section>
                <div className='container-fluid HomeContentMain p-0 d-sm-none d-none d-md-block AbouTMainImg' style={{ backgroundImage: `url(${require("../Img/About.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'top', }}>
                    <Navbar />
                    <div className="container mt-5 pt-5">
                        <div className="row d-flex justify-content-center align-items-center text-center g-4 ">
                            <div className='playfair-display'>
                                <h2 className='mb-3' style={{ fontSize: "55px", fontWeight: "800" }}>
                                    Testimonial
                                </h2>
                                <p className='mb-3 laila-semibold' style={{ fontSize: "18px", fontWeight: "400" }}>
                                    Look at what our client say’s about us!
                                </p>
                                <p className='mb-3 ' style={{ fontSize: "18px", fontWeight: "400" }}>
                                    Home &nbsp; - &nbsp;
                                    <NavLink className='text-decoration-none' style={{ color: "#F4A492" }}>
                                        <span>
                                            Testimonial
                                        </span>
                                    </NavLink>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='container-fluid HomeContentMain p-0  d-md-none d-sm-block AbouTMainImg' style={{ backgroundImage: `url(${require("../Img/About.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'top', }} >
                    <Navbar />
                    <div className="container pt-5 pb-5 p-3">
                        <div className="row d-flex justify-content-center align-items-center text-center g-4 ">
                            <div className='playfair-display'>
                                <h2 className='mb-3' style={{ fontSize: "40px", fontWeight: "800" }}>
                                    Testimonial
                                </h2>
                                <p className='mb-3 laila-semibold' style={{ fontSize: "15px", fontWeight: "400" }}>
                                    Look at what our client say’s about us!
                                </p>
                                <p className='mb-3 ' style={{ fontSize: "18px", fontWeight: "400" }}>
                                    Home &nbsp; - &nbsp;
                                    <NavLink className='text-decoration-none' style={{ color: "#F4A492" }}>
                                        <span>
                                            Testimonial
                                        </span>
                                    </NavLink>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid d-md-none d-none d-lg-block" style={{ backgroundImage: `url(${require("../Img/testimonialSection1BG1.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "auto", backgroundPosition: 'right' }} >
                    <div className="container p-5">
                        <div className="row g-4 d-flex justify-content-around pt-5 align-items-center ">
                            <div className="col-lg-6">
                                <div className='BirdUpThirdTestimonialFirst '>
                                    <div className='BirdDownThirdTestimonialFirst' >
                                        <img className='' src={require("../Img/bird1.png")} alt="icloudsoft_logo" />
                                    </div>
                                </div>

                                <div className='AboutSectionHome'>
                                    <h6>
                                        Personalized Planning Services
                                    </h6>
                                    <h3 className='' style={{ fontSize: "50px" }}>
                                        Features of Our Wedding and Event Planning Services
                                    </h3>
                                    {/* <p>
                                        Lorem ipsum dolor sit amet consectetur adipiscing elit sed doeiusm tempor incididunt
                                    </p> */}
                                    <button>
                                        Learn More
                                    </button>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="row g-4">
                                    <div className="col-lg-6">
                                        <div className=''>
                                            <img className=' p-1' src={require("../Img/testimonialSection1-1.png")} alt="icloudsoft_logo" />
                                            <div className='Services-Content text-start'>
                                                <h2>
                                                    Customized Planning
                                                </h2>
                                                <p className='pt-2 laila-semibold' style={{ color: "#555555", fontSize: "14.5px", fontWeight: "400" }}>
                                                    By incorporating personalized themes, colors, and features, we are able to reflect the couple's style and vision for their special day.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className=''>
                                            <img className=' p-1' src={require("../Img/testimonialSection1-2.png")} alt="icloudsoft_logo" />
                                            <div className='Services-Content text-start'>
                                                <h2>
                                                    Experienced Professional
                                                </h2>
                                                <p className='pt-2 laila-semibold' style={{ color: "#555555", fontSize: "14.5px", fontWeight: "400" }}>
                                                    We will provide details about a professional with extensive experience in the industry.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className=''>
                                            <img className=' p-1' src={require("../Img/testimonialSection1-3.png")} alt="icloudsoft_logo" />
                                            <div className='Services-Content text-start'>
                                                <h2>
                                                    Vendor Management:
                                                </h2>
                                                <p className='pt-2 laila-semibold' style={{ color: "#555555", fontSize: "14.5px", fontWeight: "400" }}>
                                                    We ensure to provides valuable insights into managing vendors for weddings. including tips, best practices, and guidelines for selecting and working with vendors.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className=''>
                                            <img className=' p-1' src={require("../Img/testimonialSection1-4.png")} alt="icloudsoft_logo" />
                                            <div className='Services-Content text-start'>
                                                <h2>
                                                    Timeline Creation
                                                </h2>
                                                <p className='pt-2 laila-semibold' style={{ color: "#555555", fontSize: "14.5px", fontWeight: "400" }}>
                                                    Constructing a timeline that highlights important milestones such as engagement, bridal shower, bachelor/bachelorette parties, and rehearsal dinner is our job.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid d-lg-none d-md-block" style={{ backgroundImage: `url(${require("../Img/testimonialSection1BG1.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "auto", backgroundPosition: 'right' }} >
                    <div className="container-lg p-3">
                        <div className="row g-4 d-flex justify-content-around pt-3 align-items-center ">
                            <div className="col-lg-6 ">

                                <div className='AboutSectionHome'>
                                    <h6>
                                        Personalized Planning Services
                                    </h6>
                                    <h3 className='' style={{ fontSize: "30px" }}>
                                        Features of Our Wedding and Event Planning Services
                                    </h3>
                                    {/* <p>
                                        Lorem ipsum dolor sit amet consectetur adipiscing elit sed doeiusm tempor incididunt
                                    </p> */}
                                    <button>
                                        Learn More
                                    </button>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="row g-4">
                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className=''>
                                            <img className=' p-1' src={require("../Img/testimonialSection1-1.png")} alt="icloudsoft_logo" />
                                            <div className='Services-Content text-start'>
                                                <h2>
                                                    Customized Planning
                                                </h2>
                                                <p className='pt-2 laila-semibold' style={{ color: "#555555", fontSize: "14.5px", fontWeight: "400" }}>
                                                    By incorporating personalized themes, colors, and features, we are able to reflect the couple's style and vision for their special day.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className=''>
                                            <img className=' p-1' src={require("../Img/testimonialSection1-2.png")} alt="icloudsoft_logo" />
                                            <div className='Services-Content text-start'>
                                                <h2>
                                                    Experienced Professional
                                                </h2>
                                                <p className='pt-2 laila-semibold' style={{ color: "#555555", fontSize: "14.5px", fontWeight: "400" }}>
                                                    We will provide details about a professional with extensive experience in the industry.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className=''>
                                            <img className=' p-1' src={require("../Img/testimonialSection1-3.png")} alt="icloudsoft_logo" />
                                            <div className='Services-Content text-start'>
                                                <h2>
                                                    Vendor Management:
                                                </h2>
                                                <p className='pt-2 laila-semibold' style={{ color: "#555555", fontSize: "14.5px", fontWeight: "400" }}>
                                                    We ensure to provides valuable insights into managing vendors for weddings. including tips, best practices, and guidelines for selecting and working with vendors.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className=''>
                                            <img className=' p-1' src={require("../Img/testimonialSection1-4.png")} alt="icloudsoft_logo" />
                                            <div className='Services-Content text-start'>
                                                <h2>
                                                    Timeline Creation
                                                </h2>
                                                <p className='pt-2 laila-semibold' style={{ color: "#555555", fontSize: "14.5px", fontWeight: "400" }}>
                                                    Constructing a timeline that highlights important milestones such as engagement, bridal shower, bachelor/bachelorette parties, and rehearsal dinner is our job.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Silder />

        </>
    )
}

export default Testimonial