import React from 'react'
import "./Navbar.css"
import { NavLink } from 'react-router-dom'

const Navbar = () => {
    return (
        <>

            <section>
                <div className="container-fluid p-0 d-md-none d-none d-lg-block">
                    <nav className="navbar navbar-expand-lg navbar p-0" >
                        <div className="container ">
                            <div className='mb-1 ms-5'>
                                <img className='' src={require("../Img/logo.png")} alt="icloudsoft_logo" />
                            </div>
                            <button className="navbar-toggler bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon" ></span>
                            </button>
                            <div className="collapse navbar-collapse " id="navbarSupportedContent">
                                <ul className="navbar-nav d-flex justify-content-start align-items-center ms-5 ps-5">
                                    <li className="nav-item MainPadding" >
                                        <a className="nav-link " style={{ color: "#F4A492" }} aria-current="page" href="/">Home</a>
                                    </li>

                                    <li className="nav-item MainPadding">
                                        <NavLink className="nav-link " aria-current="page" to="/about">About</NavLink>
                                    </li>

                                    <li className="nav-item MainPadding">
                                        <NavLink className="nav-link " aria-current="page" to="/services">Services</NavLink>
                                    </li>

                                    {/* <li className="nav-item MainPadding">
                                        <NavLink className="nav-link " aria-current="page" to="/services">Pages</NavLink>
                                    </li> */}

                                    <li className="nav-item dropdown MainPadding">
                                        <NavLink className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            Pages
                                        </NavLink>
                                        <ul className="dropdown-menu"  >
                                            {/* <li className="nav-item dropend ">
                                                <NavLink className=" dropdown-toggle text-decoration-none ExtraContent" to='/Pricing'  >
                                                    Pricing  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                                                </NavLink>

                                                <ul className="dropdown-menu"  >
                                                    <li><NavLink className="dropdown-item text-decoration-none bg-black" to='/Pricing'> Pricing 1 </NavLink></li>
                                                </ul>
                                            </li>

                                            <li className="nav-item dropend">
                                                <NavLink className=" dropdown-toggle text-decoration-none ExtraContent"  >
                                                    Team  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;
                                                </NavLink>

                                                <ul className="dropdown-menu"  >
                                                    <li><NavLink className="dropdown-item text-decoration-none bg-black" to='/team'> Team 1 </NavLink></li>
                                                </ul>
                                            </li> */}

                                            <li className=''><NavLink className="dropdown-item text-decoration-none bg-black" to="/pricing"> Pricing </NavLink></li>

                                            <li className=''><NavLink className="dropdown-item text-decoration-none" to="/team"> Team </NavLink></li>

                                            <li className=''><NavLink className="dropdown-item text-decoration-none bg-black" to="/testimonial"> Testimonial </NavLink></li>

                                            <li className=''><NavLink className="dropdown-item text-decoration-none" to="/gallery"> Gallery </NavLink></li>

                                            <li className=''><NavLink className="dropdown-item text-decoration-none " to="/contact"> Contact Us </NavLink></li>

                                        </ul>
                                    </li>

                                    <li className="nav-item MainPadding">
                                        <NavLink className="nav-link " aria-current="page" to="/">Blog</NavLink>
                                    </li>

                                    <li>
                                        <div className='d-flex justify-content-center pt-lg-1 ms-lg-3 ps-lg-3'>
                                            <NavLink className='text-decoration-none' to='/contact'>
                                                <button className='header-logo-contact-section-btn'>
                                                    Contact Us
                                                </button>
                                            </NavLink>
                                        </div>
                                    </li>
                                </ul>


                            </div>
                        </div>
                    </nav>
                </div>

                <div className="container-fluid p-0 d-lg-none d-md-block">
                    <nav className="navbar navbar-expand-lg p-0 " >
                        <div className="container-fluid p-0 ">
                            <div className='mb-1 ms-2 mt-3 mb-3'>
                                <img className='' src={require("../Img/logo.png")} alt="icloudsoft_logo" />
                            </div>
                            <button className="navbar-toggler me-3" type="button " data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                <i className="fa-solid fa-bars " style={{ color: "black" }}></i>
                            </button>
                            <div className="collapse navbar-collapse w-100 p-0 " id="navbarNavDropdown">
                                <ul className="navbar-nav  p-3 pt-4 mt-3 pb-4 mb-3" style={{ backgroundColor: "#fff", borderRadius: "10px", width: "95%", marginLeft: "8px", boxShadow: "0 0px 100px 0 rgba(0, 0, 0, 0.15)" }} >
                                    <li className='nav-item text-start pb-3 mt-2 border-bottom' >
                                        <NavLink className="text-white text-decoration-none fs-6  " to="/" > <b className='' style={{ fontWeight: "600", color: "#F4A492" }}> Home </b> </NavLink>
                                    </li>

                                    <li className='nav-item text-start pb-3 mt-2 border-bottom' >
                                        <NavLink className="text-white text-decoration-none fs-6  " to="/about" > <b className='' style={{ fontWeight: "600", color: "black" }} > About </b> </NavLink>
                                    </li>

                                    <li className='nav-item text-start pb-3 mt-2 border-bottom' >
                                        <NavLink className="text-white text-decoration-none fs-6  " to="/services" > <b className='' style={{ fontWeight: "600", color: "black" }} > Services </b> </NavLink>
                                    </li>

                                    {/* <li className='nav-item text-start pb-3 mt-2 border-bottom' >
                                        <NavLink className="text-white text-decoration-none fs-6  " to="/solution" > <b className='' style={{ fontWeight: "600", color: "black" }} > Pages </b> </NavLink>
                                    </li> */}

                                    <li className="nav-item #submenu nav-link  border-bottom" data-bs-toggle="collapse" aria-current="true" >
                                        <div className="dropdown-toggle  fs-6 " style={{ fontWeight: "400", color: "#111" }} id="mega-one" role="button" data-bs-toggle="dropdown" aria-expanded="true"><b className='' style={{ fontWeight: "600" }}> Pages </b></div>
                                        <ul className="dropdown-menu nav collapse p-2 " aria-labelledby="mega-one" style={{ zIndex: "6", borderRadius: "0px 0px 0px 0px", border: "none" }} id="submenu">
                                            <li className='nav-item '>
                                                <div className=' '>
                                                    <div className="row d-flex justify-content-start  fs-6">
                                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 ">
                                                            <NavLink className='text-decoration-none' to="/pricing">
                                                                <div className='dropdown-item text-dark fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", }}> Pricing </b></div>
                                                                <div className="border-bottom" ></div>
                                                            </NavLink>
                                                            <NavLink className='text-decoration-none' to="/team">
                                                                <div className='dropdown-item text-dark fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", }}> Team </b></div>
                                                                <div className="border-bottom" ></div>
                                                            </NavLink>
                                                            <NavLink className='text-decoration-none' to="/testimonial">
                                                                <div className='dropdown-item text-dark fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", }}> Testimonial </b></div>
                                                                <div className="border-bottom" ></div>
                                                            </NavLink>
                                                            <NavLink className='text-decoration-none' to="/gallery">
                                                                <div className='dropdown-item text-dark fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", }}> Gallery </b></div>
                                                                <div className="border-bottom" ></div>
                                                            </NavLink>
                                                            <NavLink className='text-decoration-none' to="/contact">
                                                                <div className='dropdown-item text-dark fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", }}> Contact Us </b></div>
                                                                {/* <div className="border-bottom" ></div> */}
                                                            </NavLink>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className='nav-item text-start pb-3 mt-2 border-bottom' >
                                        <NavLink className="text-white text-decoration-none fs-6  " to="/about" > <b className='' style={{ fontWeight: "600", color: "black" }} > Blog </b> </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </section>

        </>
    )
}

export default Navbar