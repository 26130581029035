import React from 'react'
import "./Services.css"
import { NavLink } from 'react-router-dom'
import Navbar from './Navbar'

const Services = () => {
    return (
        <>

            <section>
                <div className='container-fluid HomeContentMain p-0 d-sm-none d-none d-md-block AbouTMainImg' style={{ backgroundImage: `url(${require("../Img/About.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'top', }}>
                    <Navbar />
                    <div className="container mt-5 pt-5">
                        <div className="row d-flex justify-content-center align-items-center text-center g-4 ">
                            <div className='playfair-display'>
                                <h2 className='mb-3' style={{ fontSize: "55px", fontWeight: "800" }}>
                                    Services
                                </h2>
                                <p className='mb-3 laila-semibold' style={{ fontSize: "18px", fontWeight: "400" }}>
                                    We simplify the wedding planning process by providing a centralized hub for couples to organize their big day. <br /> From creating seating charts to making it more efficient and stress-free for soon-to-be-wed couples.
                                </p>
                                <p className='mb-3 ' style={{ fontSize: "18px", fontWeight: "400" }}>
                                    Home &nbsp; - &nbsp;
                                    <NavLink className='text-decoration-none' style={{ color: "#F4A492" }}>
                                        <span>
                                            Services
                                        </span>
                                    </NavLink>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='container-fluid HomeContentMain p-0  d-md-none d-sm-block AbouTMainImg' style={{ backgroundImage: `url(${require("../Img/About.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'top', }} >
                    <Navbar />
                    <div className="container pt-5 pb-5 p-3">
                        <div className="row d-flex justify-content-center align-items-center text-center g-4 ">
                            <div className='playfair-display'>
                                <h2 className='mb-3' style={{ fontSize: "40px", fontWeight: "800" }}>
                                    Services
                                </h2>
                                <p className='mb-3 laila-semibold' style={{ fontSize: "15px", fontWeight: "400" }}>
                                    We simplify the wedding planning process by providing a centralized hub for couples to organize their big day. From creating seating charts to making it more efficient and stress-free for soon-to-be-wed couples.
                                </p>
                                <p className='mb-3 ' style={{ fontSize: "18px", fontWeight: "400" }}>
                                    Home &nbsp; - &nbsp;
                                    <NavLink className='text-decoration-none' style={{ color: "#F4A492" }}>
                                        <span>
                                            Services
                                        </span>
                                    </NavLink>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid d-md-none d-none d-lg-block" >
                    <div className="container p-5">
                        <div className="row g-4 d-flex justify-content-center align-items-center text-center">
                            <div className="col-lg-6">
                                <div className="row">
                                    <div className="col-lg-6 p-0" style={{ border: "1px solid #9ACCC9" }}>
                                        <div className='ServiceBox'>
                                            <img className='' style={{ height: "90px" }} src={require("../Img/NextEvent3.png")} alt="icloudsoft_logo" />
                                            <h3>
                                                Wedding Planning
                                            </h3>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 p-0" style={{ border: "1px solid #9ACCC9" }}>
                                        <div className='ServiceBox'>
                                            <img className='' style={{ height: "90px" }} src={require("../Img/NextEvent4.png")} alt="icloudsoft_logo" />
                                            <h3>
                                                Corporate Events
                                            </h3>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 p-0" style={{ border: "1px solid #9ACCC9" }}>
                                        <div className='ServiceBox'>
                                            <img className='' style={{ height: "90px" }} src={require("../Img/NextEvent1.png")} alt="icloudsoft_logo" />
                                            <h3>
                                                Social Gatherings
                                            </h3>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 p-0" style={{ border: "1px solid #9ACCC9" }}>
                                        <div className='ServiceBox'>
                                            <img className='' style={{ height: "90px" }} src={require("../Img/NextEvent1.png")} alt="icloudsoft_logo" />
                                            <h3>
                                                Enteraintment
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className='CategorySectionHome'>
                                    <div className='mb-4'>
                                        <img className='' src={require("../Img/Service1.png")} alt="icloudsoft_logo" />
                                    </div>
                                    <h3>
                                        Let’s Plan Your Next Event Together
                                    </h3>
                                    <p>
                                        From the start of your event to its conclusion, we will handle everything.
                                    </p>
                                    <button>
                                        Learn More
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid d-lg-none d-md-block" >
                    <div className="container p-3">
                        <div className="row g-4 d-flex justify-content-center align-items-center text-center">
                            <div className="col-lg-6">
                                <div className="row">
                                    <div className="col-lg-6 col-6 p-0" style={{ border: "1px solid #9ACCC9" }}>
                                        <div className='ServiceBox'>
                                            <img className='' style={{ height: "90px" }} src={require("../Img/NextEvent3.png")} alt="icloudsoft_logo" />
                                            <h3>
                                                Wedding Planning
                                            </h3>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-6 p-0" style={{ border: "1px solid #9ACCC9" }}>
                                        <div className='ServiceBox'>
                                            <img className='' style={{ height: "90px" }} src={require("../Img/NextEvent4.png")} alt="icloudsoft_logo" />
                                            <h3>
                                                Corporate Events
                                            </h3>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-6 p-0" style={{ border: "1px solid #9ACCC9" }}>
                                        <div className='ServiceBox'>
                                            <img className='' style={{ height: "90px" }} src={require("../Img/NextEvent1.png")} alt="icloudsoft_logo" />
                                            <h3>
                                                Social Gatherings
                                            </h3>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-6 p-0" style={{ border: "1px solid #9ACCC9" }}>
                                        <div className='ServiceBox'>
                                            <img className='' style={{ height: "90px" }} src={require("../Img/NextEvent1.png")} alt="icloudsoft_logo" />
                                            <h3>
                                                Enteraintment
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className='CategorySectionHome'>
                                    <div className='mb-4 mt-5'>
                                        <img className='w-25' src={require("../Img/Service1.png")} alt="icloudsoft_logo" />
                                    </div>
                                    <h3 className='' style={{ fontSize: "30px" }}>
                                        Let’s Plan Your Next Event Together
                                    </h3>
                                    <p className='' style={{ fontSize: "16px" }}>
                                        From the start of your event to its conclusion, we will handle everything.
                                    </p>
                                    <button>
                                        Learn More
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid d-md-none d-none d-lg-block" style={{ backgroundImage: `url(${require("../Img/CategoryBg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "auto", backgroundPosition: 'center', backgroundColor: "#EDF7F8" }}>
                    <div className="container p-5">
                        <div className="row g-4 d-flex justify-content-around pt-5 align-items-center ">
                            <div className="col-lg-6">
                                <div className=' HeartsUp'>
                                    <div className='mb-4  HeartsDown' >
                                        <img className='w-100' src={require("../Img/hearts.png")} alt="icloudsoft_logo" />
                                    </div>
                                </div>

                                <div className='CategorySectionHome text-center'>
                                    <div className='mb-4'>
                                        <img className='' src={require("../Img/Category1.png")} alt="icloudsoft_logo" />
                                    </div>
                                    <h3>
                                        Includes Various Product Categories
                                    </h3>
                                    <p>
                                        Everything you require for your occasion is here.
                                    </p>
                                    <button>
                                        Learn More
                                    </button>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="row g-4">
                                    <div className="col-lg-6">
                                        <div className='SingleService'>
                                            <img className='w-100 p-1' src={require("../Img/Category2.png")} alt="icloudsoft_logo" />
                                            <div className='Services-Content'>
                                                <h2>
                                                    Wedding Walls
                                                </h2>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className='SingleService'>
                                            <img className='w-100 p-1' src={require("../Img/Category3.png")} alt="icloudsoft_logo" />
                                            <div className='Services-Content'>
                                                <h2>
                                                    Metal Circles & Flowers
                                                </h2>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className='SingleService'>
                                            <img className='w-100 p-1' src={require("../Img/Category4.png")} alt="icloudsoft_logo" />
                                            <div className='Services-Content'>
                                                <h2>
                                                    Chairs
                                                </h2>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className='SingleService'>
                                            <img className='w-100 p-1' src={require("../Img/Category5.png")} alt="icloudsoft_logo" />
                                            <div className='Services-Content'>
                                                <h2>
                                                    Catering
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid d-lg-none d-md-block" style={{ backgroundImage: `url(${require("../Img/CategoryBg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "auto", backgroundPosition: 'center', backgroundColor: "#EDF7F8" }}>
                    <div className="container p-3">
                        <div className="row g-4 d-flex justify-content-center pt-5 pb-5 align-items-center text-center">
                            <div className="col-lg-6 col-md-6">
                                <div className='HeartsUp'>
                                    <div className=' HeartsDown' >
                                        <img className='' src={require("../Img/hearts.png")} alt="icloudsoft_logo" />
                                    </div>
                                </div>
                                <div className='CategorySectionHome'>
                                    <div className='mb-4'>
                                        <img className='' src={require("../Img/Category1.png")} alt="icloudsoft_logo" />
                                    </div>
                                    <h3 className='' style={{ fontSize: "30px" }}>
                                        Includes Various Product Categories
                                    </h3>
                                    <p className='' style={{ fontSize: "16px" }}>
                                        Everything you require for your occasion is here.
                                    </p>
                                    <button>
                                        Learn More
                                    </button>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="row g-4">
                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='SingleService'>
                                            <img className='w-100 p-1' src={require("../Img/Category2.png")} alt="icloudsoft_logo" />
                                            <div className='Services-Content'>
                                                <h2>
                                                    Wedding Walls
                                                </h2>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='SingleService'>
                                            <img className='w-100 p-1' src={require("../Img/Category3.png")} alt="icloudsoft_logo" />
                                            <div className='Services-Content'>
                                                <h2>
                                                    Metal Circles & Flowers
                                                </h2>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='SingleService'>
                                            <img className='w-100 p-1' src={require("../Img/Category4.png")} alt="icloudsoft_logo" />
                                            <div className='Services-Content'>
                                                <h2>
                                                    Chairs
                                                </h2>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='SingleService'>
                                            <img className='w-100 p-1' src={require("../Img/Category5.png")} alt="icloudsoft_logo" />
                                            <div className='Services-Content'>
                                                <h2>
                                                    Catering
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid d-md-none d-none d-lg-block" >
                    <div className="container p-5">
                        <div className='AboutSectionHome text-center mt-3 pb-3 mb-4'>
                            <h6 className='mb-2'>
                                Introduction Video
                            </h6>
                            <h3 className='' style={{ fontSize: "50px" }}>
                                Bridely Presentation
                            </h3>
                        </div>
                        <div className=''>
                            <iframe className='w-100 m-0 MapSize' title="gmap" style={{ borderRadius: "50px 0px 50px 0px" }} src="https://www.youtube.com/embed/XHOmBV4js_E?feature=oembed&start&end&wmode=opaque&loop=0&controls=0&mute=0&rel=0&modestbranding=0&autoplay=1">
                            </iframe>
                        </div>
                    </div>
                </div>

                <div className="container-fluid d-lg-none d-md-block" >
                    <div className="container-lg p-3">
                        <div className='AboutSectionHome text-center mt-3 pb-3 mb-3'>
                            <h6 className='mb-2'>
                                Introduction Video
                            </h6>
                            <h3 className='mt-3' style={{ fontSize: "30px" }}>
                                Bridely Presentation
                            </h3>
                        </div>
                        <div className='mt-2'>
                            <iframe className='w-100 m-0 MapSize' title="gmap" style={{ borderRadius: "50px 0px 50px 0px" }} src="https://www.youtube.com/embed/XHOmBV4js_E?feature=oembed&start&end&wmode=opaque&loop=0&controls=0&mute=0&rel=0&modestbranding=0&autoplay=1">
                            </iframe>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Services