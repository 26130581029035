import React, { useState } from 'react'
import "./About.css"
import { NavLink } from 'react-router-dom'
import Navbar from './Navbar'
import ScrollTrigger from "react-scroll-trigger"
import CountUp from 'react-countup';

const About = () => {

    const [counterOn, setCounterOn] = useState(false);

    return (
        <>

            <section>
                <div className='container-fluid HomeContentMain p-0 d-sm-none d-none d-md-block AbouTMainImg' style={{ backgroundImage: `url(${require("../Img/About.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'top', }}>
                    <Navbar />
                    <div className="container mt-5 pt-5">
                        <div className="row d-flex justify-content-center align-items-center text-center g-4 ">
                            <div className='playfair-display'>
                                <h2 className='mb-3' style={{ fontSize: "55px", fontWeight: "800" }}>
                                    About
                                </h2>
                                <p className='mb-3 laila-semibold' style={{ fontSize: "18px", fontWeight: "400" }}>
                                    Our objective is to help every couple have a stress-free and joyful wedding planning experience. <br />We have you covered if you need inspiration, helpful ideas, or vendor referrals.
                                </p>
                                <p className='mb-3 ' style={{ fontSize: "18px", fontWeight: "400" }}>
                                    Home &nbsp; - &nbsp;
                                    <NavLink className='text-decoration-none' style={{ color: "#F4A492" }}>
                                        <span>
                                            About
                                        </span>
                                    </NavLink>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='container-fluid HomeContentMain p-0  d-md-none d-sm-block AbouTMainImg' style={{ backgroundImage: `url(${require("../Img/About.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'top', }} >
                    <Navbar />
                    <div className="container pt-5 pb-5 p-3">
                        <div className="row d-flex justify-content-center align-items-center text-center g-4 ">
                            <div className='playfair-display'>
                                <h2 className='mb-3' style={{ fontSize: "40px", fontWeight: "800" }}>
                                    About
                                </h2>
                                <p className='mb-3 laila-semibold' style={{ fontSize: "15px", fontWeight: "400" }}>
                                    Our objective is to help every couple have a stress-free and joyful wedding planning experience. We have you covered if you need inspiration, helpful ideas, or vendor referrals.
                                </p>
                                <p className='mb-3 ' style={{ fontSize: "18px", fontWeight: "400" }}>
                                    Home &nbsp; - &nbsp;
                                    <NavLink className='text-decoration-none' style={{ color: "#F4A492" }}>
                                        <span>
                                            About
                                        </span>
                                    </NavLink>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container p-5 d-sm-none d-none d-lg-block pt-lg-5 ">
                    <div className="row g-4 d-flex justify-content-around  pt-lg-5">
                        <div className="col-lg-5 col-md-6">
                            <div className='AboutSectionHome'>
                                <h6>
                                    Who We Are
                                </h6>
                                <h3 className='' style={{ fontSize: "50px" }}>
                                    Planner for Your Perfect Wedding
                                </h3>
                                <p>
                                    When you work with us, you can simply put away your endless spreadsheets and uninteresting to-do lists. Let us assist you in simplifying the arrangements so you can focus on enjoying this memorable moment in your life.
                                </p>
                                <h5>
                                    <i class="fa-regular fa-circle-check"></i>
                                    <span className='ps-2'>
                                        Invests a Significant Amount of Time with You
                                    </span>
                                </h5>
                                <h5>
                                    <i class="fa-regular fa-circle-check"></i>
                                    <span className='ps-2'>
                                        Helps with Details and Reminders
                                    </span>
                                </h5>
                                <h5>
                                    <i class="fa-regular fa-circle-check"></i>
                                    <span className='ps-2'>
                                        Provides Budget Assistance
                                    </span>
                                </h5>
                                <button>
                                    Learn More
                                </button>
                            </div>
                        </div>

                        <div className='col-lg-2 col-md-2'>
                            <div className='AboutSectionFirtsbirdUp'>
                                <div className='AboutSectionFirtsbirdDown' >
                                    <img className='' src={require("../Img/bird.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-4">
                            <div className='about-1'>
                                <img className='' style={{ width: "90%" }} src={require("../Img/about-1.png")} alt="icloudsoft_logo" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-lg p-4 d-lg-none d-sm-block pt-5 ">
                    <div className="row g-4 d-flex justify-content-around  pt-lg-5">
                        <div className="col-lg-5 col-md-6 col-sm-7">
                            <div className='AboutSectionHome'>
                                <h6>
                                    Who We Are
                                </h6>
                                <h3 className='' style={{ fontSize: "40px" }}>
                                    Planner for Your Perfect Wedding
                                </h3>
                                <p>
                                    When you work with us, you can simply put away your endless spreadsheets and uninteresting to-do lists. Let us assist you in simplifying the arrangements so you can focus on enjoying this memorable moment in your life.
                                </p>
                                <h5>
                                    <i class="fa-regular fa-circle-check"></i>
                                    <span className='ps-2'>
                                        Invests a Significant Amount of Time with You
                                    </span>
                                </h5>
                                <h5>
                                    <i class="fa-regular fa-circle-check"></i>
                                    <span className='ps-2'>
                                        Helps with Details and Reminders
                                    </span>
                                </h5>
                                <h5>
                                    <i class="fa-regular fa-circle-check"></i>
                                    <span className='ps-2'>
                                        Provides Budget Assistance
                                    </span>
                                </h5>
                                <button>
                                    Learn More
                                </button>
                            </div>
                        </div>

                        <div className="col-lg-5 col-md-6 col-sm-5">
                            <div className='about-1'>
                                <img className='w-100' src={require("../Img/about-1.png")} alt="icloudsoft_logo" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid d-md-none d-none d-lg-block" style={{ backgroundImage: `url(${require("../Img/CategoryBg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "auto", backgroundPosition: 'right', backgroundColor: "#EDF7F8" }}>
                    <div className="container p-5 pt-lg-5">
                        <div className="row g-4 d-flex justify-content-center align-items-center pt-lg-5">
                            <div className="col-lg-6 col-md-6">
                                <div className='AboutSet2-1 position-relative'>
                                    <img className='' src={require("../Img/HomeSeT2-1.png")} alt="icloudsoft_logo" />

                                    <div className='AboutSet2-2 position-absolute' style={{ bottom: "-10%", right: "2%", top: "72%" }}>
                                        <img className='' src={require("../Img/HomeSeT2-2.png")} alt="icloudsoft_logo" />
                                    </div>
                                </div>

                                <div className='AboutSet2-3 text-start '>
                                    <img className='' src={require("../Img/HomeSeT2-3.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <div className='CategorySectionHome'>
                                    <div className=' AboutHeartsUpSecond'>
                                        <div className='mb-4  AboutHeartsDownSecond' >
                                            <img className='w-75' src={require("../Img/hearts.png")} alt="icloudsoft_logo" />
                                        </div>
                                    </div>

                                    <div className='AboutSectionHome'>
                                        <h6>
                                            About Bridely
                                        </h6>
                                        <h3>
                                            What We do, We do With Passion
                                        </h3>
                                        <p>
                                            Everything entirely customizable that you require for a stress-free wedding
                                        </p>
                                        <button>
                                            Learn More
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid d-lg-none d-md-block" style={{ backgroundImage: `url(${require("../Img/CategoryBg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "auto", backgroundPosition: 'right', backgroundColor: "#EDF7F8" }}>
                    <div className="container p-3 pt-lg-5">
                        <div className="row g-4 d-flex justify-content-center align-items-center pt-lg-5">
                            <div className="col-lg-6 col-md-6">
                                <div className='AboutSet2-1 position-relative'>
                                    <img className='' src={require("../Img/HomeSeT2-1.png")} alt="icloudsoft_logo" />

                                    <div className='AboutSet2-2 position-absolute' style={{ bottom: "-10%", right: "5%", top: "78%" }}>
                                        <img className='' src={require("../Img/HomeSeT2-2.png")} alt="icloudsoft_logo" />
                                    </div>
                                </div>

                                <div className='AboutSet2-3 text-start '>
                                    <img className='' src={require("../Img/HomeSeT2-3.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <div className='CategorySectionHome'>

                                    <div className='AboutSectionHome'>
                                        <h6>
                                            About Bridely
                                        </h6>
                                        <h3>
                                            What We do, We do With Passion
                                        </h3>
                                        <p>
                                            Everything entirely customizable that you require for a stress-free wedding
                                        </p>
                                        <button>
                                            Learn More
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='container-fluid d-sm-none d-none d-lg-block' >
                    <div className="container p-lg-5 playfair-display" >
                        <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                            <div className="row g-4 ps-lg-5 d-flex justify-content-center align-items-center text-center" style={{ backgroundColor: "#F4A492", borderRadius: "60px 0px 60px 0px", border: "5px solid #fff", boxShadow: "0px 0px 100px 0px rgba(0, 0, 0, 0.1)", padding: "18px" }}>
                                <div className="col-xl-3 col-md-3  d-flex justify-content-center align-items-center">
                                    <div className='MainWidth d-flex justify-content-between align-items-center p-2'>
                                        <div className=''>
                                            <div className='playfair-display' style={{ fontSize: "40px", color: "#fff", fontWeight: "800" }}> {counterOn && <CountUp start={0} end={1565} duration={2} delay={0}></CountUp>} + </div>
                                            <p className=' ' style={{ fontSize: "18px", lineHeight: "2", color: "#fff", fontWeight: "700" }}>Happy Couples</p>
                                        </div>
                                        <div className='CommonBorder'>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-3 col-md-3 d-flex justify-content-center align-items-center">
                                    <div className='MainWidth d-flex justify-content-between align-items-center p-2'>
                                        <div className=''>
                                            <div className='playfair-display' style={{ fontSize: "40px", color: "#fff", fontWeight: "800" }}> {counterOn && <CountUp start={0} end={1680} duration={2} delay={0}> </CountUp>} + </div>
                                            <p className='' style={{ fontSize: "18px", lineHeight: "2", color: "#fff", fontWeight: "700" }}>Weddings</p>
                                        </div>

                                        <div className='CommonBorder'>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-3 col-md-3 d-flex justify-content-center align-items-center">
                                    <div className='MainWidth d-flex justify-content-between align-items-center p-2'>
                                        <div className=''>
                                            <div className='playfair-display' style={{ fontSize: "40px", color: "#fff", fontWeight: "800" }}> {counterOn && <CountUp start={0} end={845} duration={2} delay={0}> </CountUp>} + </div>
                                            <p className='' style={{ fontSize: "18px", lineHeight: "2", color: "#fff", fontWeight: "700" }}>Decoration</p>
                                        </div>

                                        <div className='CommonBorder'>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-3 col-md-3 d-flex justify-content-center align-items-center">
                                    <div className='MainWidth d-flex justify-content-between align-items-center p-2'>
                                        <div className=''>
                                            <div className='playfair-display' style={{ fontSize: "40px", color: "#fff", fontWeight: "800" }}> {counterOn && <CountUp start={0} end={175} duration={2} delay={0}> </CountUp>} + </div>
                                            <p className='' style={{ fontSize: "18px", lineHeight: "2", color: "#fff", fontWeight: "700" }}>Locations</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </ScrollTrigger>
                    </div>
                </div>

                <div className='container-fluid d-lg-none d-sm-block' >
                    <div className="container p-3 mt-5 mb-4 playfair-display" >
                        <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                            <div className="row g-4 ps-lg-5 d-flex justify-content-center align-items-center text-center" style={{ backgroundColor: "#F4A492", borderRadius: "60px 0px 60px 0px", border: "5px solid #fff", boxShadow: "0px 0px 100px 0px rgba(0, 0, 0, 0.1)", padding: "18px" }}>
                                <div className="col-xl-3 col-md-6 col-6 d-flex justify-content-center align-items-center">
                                    <div className='MainWidth d-flex justify-content-between align-items-center p-1'>
                                        <div className=''>
                                            <div className='playfair-display' style={{ fontSize: "27px", color: "#fff", fontWeight: "800" }}> {counterOn && <CountUp start={0} end={1565} duration={2} delay={0}></CountUp>} + </div>
                                            <p className=' ' style={{ fontSize: "13px", lineHeight: "2", color: "#fff", fontWeight: "700" }}>Happy Couples</p>
                                        </div>
                                        <div className='CommonBorder'>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-3 col-md-6 col-6 d-flex justify-content-center align-items-center">
                                    <div className='MainWidth d-flex justify-content-between align-items-center p-1'>
                                        <div className=''>
                                            <div className='playfair-display' style={{ fontSize: "27px", color: "#fff", fontWeight: "800" }}> {counterOn && <CountUp start={0} end={1680} duration={2} delay={0}> </CountUp>} + </div>
                                            <p className='' style={{ fontSize: "15px", lineHeight: "2", color: "#fff", fontWeight: "700" }}>Weddings</p>
                                        </div>


                                    </div>
                                </div>

                                <div className="col-xl-3 col-md-6 col-6 d-flex justify-content-center align-items-center">
                                    <div className='MainWidth d-flex justify-content-between align-items-center p-1'>
                                        <div className=''>
                                            <div className='playfair-display' style={{ fontSize: "27px", color: "#fff", fontWeight: "800" }}> {counterOn && <CountUp start={0} end={845} duration={2} delay={0}> </CountUp>} + </div>
                                            <p className='' style={{ fontSize: "15px", lineHeight: "2", color: "#fff", fontWeight: "700" }}>Decoration</p>
                                        </div>

                                        <div className='CommonBorder'>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-3 col-md-6 col-6 d-flex justify-content-center align-items-center">
                                    <div className='MainWidth d-flex justify-content-between align-items-center p-1'>
                                        <div className=''>
                                            <div className='playfair-display' style={{ fontSize: "27px", color: "#fff", fontWeight: "800" }}> {counterOn && <CountUp start={0} end={175} duration={2} delay={0}> </CountUp>} + </div>
                                            <p className='' style={{ fontSize: "15px", lineHeight: "2", color: "#fff", fontWeight: "700" }}>Locations</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </ScrollTrigger>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid d-md-none d-none d-lg-block" >
                    <div className="container p-5">
                        <div className="row g-4 d-flex justify-content-around  mt-3 align-items-center ">
                            <div className="col-lg-6 col-md-6">

                                <div className='AboutSectionSecondbirdUp'>
                                    <div className='AboutSectionSecondbirdDown' >
                                        <img className='' src={require("../Img/bird.png")} alt="icloudsoft_logo" />
                                    </div>
                                </div>

                                <div className='CategorySectionHome '>
                                    <div className='AboutSectionHome'>
                                        <h6>
                                            Bridely Fun Facts
                                        </h6>
                                        <h3>
                                            Explore our <br /> wedding Services
                                        </h3>
                                        <p>
                                            Make every wedding an exclusive masterpiece using our technology and your artistic flair.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <div className='ProgressBar'>
                                    <div className='mt-2 mb-2'>
                                        <div>
                                            <h2>Professionality</h2>
                                        </div>
                                        <div class="progress">
                                            <div class="progress-bar" role="progressbar" style={{ width: "85%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">85%</div>
                                        </div>
                                    </div>

                                    <div className='mt-2 mb-2'>
                                        <div>
                                            <h2>Food Ordering</h2>
                                        </div>
                                        <div class="progress">
                                            <div class="progress-bar" role="progressbar" style={{ width: "70%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">70%</div>
                                        </div>
                                    </div>

                                    <div className='mt-2 mb-2'>
                                        <div>
                                            <h2>Experience And Quality</h2>
                                        </div>
                                        <div class="progress">
                                            <div class="progress-bar" role="progressbar" style={{ width: "90%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">90%</div>
                                        </div>
                                    </div>

                                    <div className='mt-2 mb-2'>
                                        <div>
                                            <h2>Guarantee</h2>
                                        </div>
                                        <div class="progress">
                                            <div class="progress-bar" role="progressbar" style={{ width: "80%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">80%</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid d-lg-none d-md-block" style={{ backgroundImage: `url(${require("../Img/CategoryBg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "auto", backgroundPosition: 'center', backgroundColor: "#EDF7F8" }}>
                    <div className="container-lg p-3">
                        <div className="row g-4 d-flex justify-content-center pt-5 pb-5 align-items-center text-center">
                            <div className="col-lg-6 col-md-6">
                                <div className='AboutSectionHome'>
                                    <h6>
                                        Bridely Fun Facts
                                    </h6>
                                    <h3>
                                        Explore our wedding Services
                                    </h3>
                                    <p>
                                        Make every wedding an exclusive masterpiece using our technology and your artistic flair.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <div className='ProgressBar'>
                                    <div className='mt-2 mb-2'>
                                        <div>
                                            <h2>Professionality</h2>
                                        </div>
                                        <div class="progress">
                                            <div class="progress-bar" role="progressbar" style={{ width: "85%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">85%</div>
                                        </div>
                                    </div>

                                    <div className='mt-2 mb-2'>
                                        <div>
                                            <h2>Food Ordering</h2>
                                        </div>
                                        <div class="progress">
                                            <div class="progress-bar" role="progressbar" style={{ width: "70%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">70%</div>
                                        </div>
                                    </div>

                                    <div className='mt-2 mb-2'>
                                        <div>
                                            <h2>Experience And Quality</h2>
                                        </div>
                                        <div class="progress">
                                            <div class="progress-bar" role="progressbar" style={{ width: "90%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">90%</div>
                                        </div>
                                    </div>

                                    <div className='mt-2 mb-2'>
                                        <div>
                                            <h2>Guarantee</h2>
                                        </div>
                                        <div class="progress">
                                            <div class="progress-bar" role="progressbar" style={{ width: "80%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">80%</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid d-md-none d-none d-lg-block" >
                    <div className="container p-5 mb-5">
                        <div className='AboutSectionHome text-center mt-3 pb-3 mb-5'>
                            <h6 className='mb-2'>
                                Introduction Video
                            </h6>
                            <h3 className='' style={{ fontSize: "50px" }}>
                                Bridely Presentation
                            </h3>
                        </div>
                        <div className=' pt-2'>
                            <iframe className='w-100 m-0' title="gmap" style={{ height: "450px", borderRadius: "50px 0px 50px 0px" }} src="https://www.youtube.com/embed/XHOmBV4js_E?feature=oembed&start&end&wmode=opaque&loop=0&controls=0&mute=0&rel=0&modestbranding=0&autoplay=1">
                            </iframe>
                        </div>
                    </div>
                </div>

                <div className="container-fluid d-lg-none d-md-block" >
                    <div className="container p-3">
                        <div className='AboutSectionHome text-center mt-3 pb-3 mb-3'>
                            <h6 className='mb-2'>
                                Introduction Video
                            </h6>
                            <h3 className='mt-3' style={{ fontSize: "30px" }}>
                                Bridely Presentation
                            </h3>
                        </div>
                        <div className='mt-2'>
                            <iframe className='w-100 m-0' title="gmap" style={{ height: "200px", borderRadius: "50px 0px 50px 0px" }} src="https://www.youtube.com/embed/XHOmBV4js_E?feature=oembed&start&end&wmode=opaque&loop=0&controls=0&mute=0&rel=0&modestbranding=0&autoplay=1">
                            </iframe>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid d-md-none d-none d-lg-block" style={{ backgroundImage: `url(${require("../Img/CategoryBg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "auto", backgroundPosition: 'center', backgroundColor: "#EDF7F8" }}>
                    <div className="container p-5">
                        <div className="row g-4 d-flex justify-content-center pt-5 align-items-center text-center">
                            <div className="col-lg-6">
                                <div className=' HeartsUp'>
                                    <div className='mb-4  HeartsDown' >
                                        <img className='w-100' src={require("../Img/hearts.png")} alt="icloudsoft_logo" />
                                    </div>
                                </div>
                                <div className='CategorySectionHome'>
                                    <div className='mb-4'>
                                        <img className='' src={require("../Img/Category1.png")} alt="icloudsoft_logo" />
                                    </div>
                                    <h3>
                                        Meet Our Creative Event Organizer
                                    </h3>
                                    <p>
                                        This group will plan your event in an orderly fashion.
                                    </p>
                                    <button>
                                        Learn More
                                    </button>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="row g-4">
                                    <div className="col-lg-6">
                                        <div className='SingleService'>
                                            <img className='w-100 p-1' src={require("../Img/Team1.png")} alt="icloudsoft_logo" />
                                            <div className='Services-Content'>
                                                <h2>
                                                    Sanskruti
                                                </h2>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className='SingleService'>
                                            <img className='w-100 p-1' src={require("../Img/Team2.png")} alt="icloudsoft_logo" />
                                            <div className='Services-Content'>
                                                <h2>
                                                    Deepak
                                                </h2>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className='SingleService'>
                                            <img className='w-100 p-1' src={require("../Img/Team3.png")} alt="icloudsoft_logo" />
                                            <div className='Services-Content'>
                                                <h2>
                                                    Sunny
                                                </h2>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className='SingleService'>
                                            <img className='w-100 p-1' src={require("../Img/Team4.png")} alt="icloudsoft_logo" />
                                            <div className='Services-Content'>
                                                <h2>
                                                    Priyanka
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid d-lg-none d-md-block" style={{ backgroundImage: `url(${require("../Img/CategoryBg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "auto", backgroundPosition: 'center', backgroundColor: "#EDF7F8" }}>
                    <div className="container p-3">
                        <div className="row g-4 d-flex justify-content-center pt-5 align-items-center text-center">
                            <div className="col-lg-6">
                                <div className='HeartsUp'>
                                    <div className=' HeartsDown' >
                                        <img className='' src={require("../Img/hearts.png")} alt="icloudsoft_logo" />
                                    </div>
                                </div>
                                <div className='CategorySectionHome'>
                                    <div className='mb-4'>
                                        <img className='w-25' src={require("../Img/Category1.png")} alt="icloudsoft_logo" />
                                    </div>
                                    <h3 className='' style={{ fontSize: "30px" }}>
                                        Meet Our Creative Event Organizer
                                    </h3>
                                    <p className='' style={{ fontSize: "16px" }}>
                                        This group will plan your event in an orderly fashion.
                                    </p>
                                    <button>
                                        Learn More
                                    </button>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="row g-4">
                                    <div className="col-lg-6 col-sm-6">
                                        <div className='SingleService'>
                                            <img className='w-100 p-1' src={require("../Img/Team1.png")} alt="icloudsoft_logo" />
                                            <div className='Services-Content'>
                                                <h2>
                                                    Sanskruti
                                                </h2>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-sm-6">
                                        <div className='SingleService'>
                                            <img className='w-100 p-1' src={require("../Img/Team2.png")} alt="icloudsoft_logo" />
                                            <div className='Services-Content'>
                                                <h2>
                                                    Deepak
                                                </h2>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-sm-6">
                                        <div className='SingleService'>
                                            <img className='w-100 p-1' src={require("../Img/Team3.png")} alt="icloudsoft_logo" />
                                            <div className='Services-Content'>
                                                <h2>
                                                    Sunny
                                                </h2>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-sm-6">
                                        <div className='SingleService'>
                                            <img className='w-100 p-1' src={require("../Img/Team4.png")} alt="icloudsoft_logo" />
                                            <div className='Services-Content'>
                                                <h2>
                                                    Priyanka
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid d-md-none d-none d-lg-block" >
                    <div className="container p-5 ">
                        <div className="row g-4 d-flex justify-content-center pt-5 pb-5 mb-5 mt-5 align-items-center text-center">
                            <div className="col-lg-5 col-md-4">

                                <div className='position-relative'>
                                    <div className='story-section position-absolute' style={{ top: "-130px", left: "3%", width: "600px" }}>
                                        <div className='birdUpSecond'>
                                            <div className='birdDownSecond' >
                                                <img className='' src={require("../Img/bird.png")} alt="icloudsoft_logo" />
                                            </div>
                                        </div>
                                        <div className='mb-4'>
                                            <img className='' src={require("../Img/quote_icon.png")} alt="icloudsoft_logo" />
                                        </div>
                                        <h3>
                                            Marry Jain & Jamie Foster
                                        </h3>
                                        <p>
                                            Bridely truly exceeded all of my expectations as a wedding planner.They took the time to understand my vision and preferences, and flawlessly executed every detail. I was able to relax and enjoy every moment of my wedding day
                                        </p>
                                        <button>
                                            See the Story
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-8">
                                <div className='story-section-img '>
                                    <img className='w-100 ' src={require("../Img/story-section-img.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid d-lg-none d-md-block" >
                    <div className="container p-3">
                        <div className="row g-4 d-flex justify-content-center mb-3 mt-3 align-items-center text-center">
                            <div className="col-lg-6 col-md-6">
                                <div className='story-section-img '>
                                    <img className='w-100 ' src={require("../Img/story-section-img.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <div className=''>
                                    <div className='story-section p-3 ' >
                                        <div className='mb-4'>
                                            <img className='' src={require("../Img/quote_icon.png")} alt="icloudsoft_logo" />
                                        </div>
                                        <h3 className='' style={{ fontSize: "25px" }}>
                                            Marry Jain & Jamie Foster
                                        </h3>
                                        <p className='' style={{ fontSize: "14px" }}>
                                            Bridely truly exceeded all of my expectations as a wedding planner.They took the time to understand my vision and preferences, and flawlessly executed every detail. I was able to relax and enjoy every moment of my wedding day
                                        </p>
                                        <button>
                                            See the Story
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid d-sm-none d-none d-md-block" style={{ backgroundImage: `url(${require("../Img/InstagramBg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "contain", height: "1300px", backgroundPosition: 'bottom' }}>
                    <div className="container p-5 text-center" >
                        <div className='d-flex justify-content-center text-center' >
                            <div className='mb-4 mt-4'>
                                <img className='' src={require("../Img/instagram_icon.png")} alt="icloudsoft_logo" />
                            </div>
                            <div className='BirdUpThird '>
                                <div className='BirdDownThird' >
                                    <img className='' src={require("../Img/bird.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>
                        </div>
                        <h3 className='fs-1 playfair-display'>
                            Instagram Feed
                        </h3>
                        <div className="row g-4 d-flex justify-content-around pt-5  align-items-center text-center">
                            <div className="col-xl-3 col-lg-6 col-md-6">
                                <div className='CommonImg'>
                                    <img className='' src={require("../Img/FooterTop1.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-6 col-md-6">
                                <div className='CommonImg mt-5'>
                                    <img className='' src={require("../Img/FooterTop2.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-6 col-md-6">
                                <div className='CommonImg'>
                                    <img className='' src={require("../Img/FooterTop3.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-6 col-md-6">
                                <div className='CommonImg mt-5'>
                                    <img className='' src={require("../Img/FooterTop4.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid d-md-none d-sm-block CoupleImg" style={{ backgroundImage: `url(${require("../Img/InstagramBg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "contain", backgroundPosition: 'bottom' }}>
                    <div className="container p-3 text-center" >
                        <div className='d-flex justify-content-center text-center' >
                            <div className='mb-4 mt-4'>
                                <img className='' src={require("../Img/instagram_icon.png")} alt="icloudsoft_logo" />
                            </div>
                            <div className='BirdUpThird '>
                                <div className='BirdDownThird' >
                                    <img className='' src={require("../Img/bird.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>
                        </div>
                        <h3 className='fs-1 playfair-display'>
                            Instagram Feed
                        </h3>
                        <div className="row g-4 d-flex justify-content-around pt-4  align-items-center text-center">
                            <div className="col-lg-3 col-6">
                                <div className='CommonImg'>
                                    <img className='w-100' src={require("../Img/FooterTop1.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>

                            <div className="col-lg-3 col-6">
                                <div className='CommonImg '>
                                    <img className='w-100' src={require("../Img/FooterTop2.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>

                            <div className="col-lg-3 col-6">
                                <div className='CommonImg'>
                                    <img className='w-100' src={require("../Img/FooterTop3.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>

                            <div className="col-lg-3 col-6">
                                <div className='CommonImg'>
                                    <img className='w-100' src={require("../Img/FooterTop4.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default About