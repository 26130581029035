import React from 'react'
import "./Team.css"
import { NavLink } from 'react-router-dom'
import Navbar from './Navbar'

const Team = () => {
    return (
        <>

            <section>
                <div className='container-fluid HomeContentMain p-0 d-sm-none d-none d-md-block AbouTMainImg' style={{ backgroundImage: `url(${require("../Img/About.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'top', }}>
                    <Navbar />
                    <div className="container mt-5 pt-5">
                        <div className="row d-flex justify-content-center align-items-center text-center g-4 ">
                            <div className='playfair-display'>
                                <h2 className='mb-3' style={{ fontSize: "55px", fontWeight: "800" }}>
                                    Team
                                </h2>
                                <p className='mb-3 laila-semibold' style={{ fontSize: "18px", fontWeight: "400" }}>
                                Many hands with one mind set
                                </p>
                                <p className='mb-3 ' style={{ fontSize: "18px", fontWeight: "400" }}>
                                    Home &nbsp; - &nbsp;
                                    <NavLink className='text-decoration-none' style={{ color: "#F4A492" }}>
                                        <span>
                                            Team
                                        </span>
                                    </NavLink>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='container-fluid HomeContentMain p-0  d-md-none d-sm-block AbouTMainImg' style={{ backgroundImage: `url(${require("../Img/About.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'top', }} >
                    <Navbar />
                    <div className="container pt-5 pb-5 p-3">
                        <div className="row d-flex justify-content-center align-items-center text-center g-4 ">
                            <div className='playfair-display'>
                                <h2 className='mb-3' style={{ fontSize: "40px", fontWeight: "800" }}>
                                    Team
                                </h2>
                                <p className='mb-3 laila-semibold' style={{ fontSize: "15px", fontWeight: "400" }}>
                                Many hands with one mind set
                                </p>
                                <p className='mb-3 ' style={{ fontSize: "18px", fontWeight: "400" }}>
                                    Home &nbsp; - &nbsp;
                                    <NavLink className='text-decoration-none' style={{ color: "#F4A492" }}>
                                        <span>
                                            Team
                                        </span>
                                    </NavLink>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container p-5 d-sm-none d-none d-lg-block pt-lg-5 ">
                    <div className="row g-4 d-flex justify-content-around  pt-lg-5">
                        <div className="col-lg-5 col-md-6">
                            <div className='AboutSectionHome'>
                                <h6>
                                    Who We Are
                                </h6>
                                <h3 className='' style={{ fontSize: "50px" }}>
                                    Planner for Your Perfect Wedding
                                </h3>
                                <p>
                                    When you work with us, you can simply put away your endless spreadsheets and uninteresting to-do lists. Let us assist you in simplifying the arrangements so you can focus on enjoying this memorable moment in your life.
                                </p>
                                <h5>
                                    <i class="fa-regular fa-circle-check"></i>
                                    <span className='ps-2'>
                                        Invests a Significant Amount of Time with You
                                    </span>
                                </h5>
                                <h5>
                                    <i class="fa-regular fa-circle-check"></i>
                                    <span className='ps-2'>
                                        Helps with Details and Reminders
                                    </span>
                                </h5>
                                <h5>
                                    <i class="fa-regular fa-circle-check"></i>
                                    <span className='ps-2'>
                                        Provides Budget Assistance
                                    </span>
                                </h5>
                                <button>
                                    Learn More
                                </button>
                            </div>
                        </div>

                        <div className='col-lg-2 col-md-2'>
                            <div className='AboutSectionFirtsbirdUp'>
                                <div className='AboutSectionFirtsbirdDown' >
                                    <img className='' src={require("../Img/bird.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-4">
                            <div className='about-1'>
                                <img className='' style={{ width: "90%" }} src={require("../Img/about-1.png")} alt="icloudsoft_logo" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-lg p-4 d-lg-none d-sm-block pt-5 ">
                    <div className="row g-4 d-flex justify-content-around  pt-lg-5">
                        <div className="col-lg-5 col-md-6 col-sm-7">
                            <div className='AboutSectionHome'>
                                <h6>
                                    Who We Are
                                </h6>
                                <h3 className='' style={{ fontSize: "40px" }}>
                                    Planner for Your Perfect Wedding
                                </h3>
                                <p>
                                    When you work with us, you can simply put away your endless spreadsheets and uninteresting to-do lists. Let us assist you in simplifying the arrangements so you can focus on enjoying this memorable moment in your life.
                                </p>
                                <h5>
                                    <i class="fa-regular fa-circle-check"></i>
                                    <span className='ps-2'>
                                        Invests a Significant Amount of Time with You
                                    </span>
                                </h5>
                                <h5>
                                    <i class="fa-regular fa-circle-check"></i>
                                    <span className='ps-2'>
                                        Helps with Details and Reminders
                                    </span>
                                </h5>
                                <h5>
                                    <i class="fa-regular fa-circle-check"></i>
                                    <span className='ps-2'>
                                        Provides Budget Assistance
                                    </span>
                                </h5>
                                <button>
                                    Learn More
                                </button>
                            </div>
                        </div>

                        <div className="col-lg-5 col-md-6 col-sm-5">
                            <div className='about-1'>
                                <img className='w-100' src={require("../Img/about-1.png")} alt="icloudsoft_logo" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid d-md-none d-none d-lg-block" style={{ backgroundImage: `url(${require("../Img/CategoryBg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "auto", backgroundPosition: 'center', backgroundColor: "#EDF7F8" }}>
                    <div className="container p-5">
                        <div className="row g-4 d-flex justify-content-center pt-5 align-items-center text-center">
                            <div className="col-lg-6">
                                <div className=' HeartsUp'>
                                    <div className='mb-4  HeartsDown' >
                                        <img className='w-100' src={require("../Img/hearts.png")} alt="icloudsoft_logo" />
                                    </div>
                                </div>
                                <div className='CategorySectionHome'>
                                    <div className='mb-4'>
                                        <img className='' src={require("../Img/Category1.png")} alt="icloudsoft_logo" />
                                    </div>
                                    <h3>
                                        Meet Our Creative Event Organizer
                                    </h3>
                                    <p>
                                        This group will plan your event in an orderly fashion.
                                    </p>
                                    <button>
                                        Learn More
                                    </button>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="row g-4">
                                    <div className="col-lg-6">
                                        <div className='SingleService'>
                                            <img className='w-100 p-1' src={require("../Img/Team1.png")} alt="icloudsoft_logo" />
                                            <div className='Services-Content'>
                                                <h2>
                                                    Sanskruti
                                                </h2>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className='SingleService'>
                                            <img className='w-100 p-1' src={require("../Img/Team2.png")} alt="icloudsoft_logo" />
                                            <div className='Services-Content'>
                                                <h2>
                                                    Deepak
                                                </h2>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className='SingleService'>
                                            <img className='w-100 p-1' src={require("../Img/Team3.png")} alt="icloudsoft_logo" />
                                            <div className='Services-Content'>
                                                <h2>
                                                    Sunny
                                                </h2>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className='SingleService'>
                                            <img className='w-100 p-1' src={require("../Img/Team4.png")} alt="icloudsoft_logo" />
                                            <div className='Services-Content'>
                                                <h2>
                                                    Priyanka
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid d-lg-none d-md-block" style={{ backgroundImage: `url(${require("../Img/CategoryBg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "auto", backgroundPosition: 'center', backgroundColor: "#EDF7F8" }}>
                    <div className="container p-3">
                        <div className="row g-4 d-flex justify-content-center pt-5 align-items-center text-center">
                            <div className="col-lg-6">
                                <div className='HeartsUp'>
                                    <div className=' HeartsDown' >
                                        <img className='' src={require("../Img/hearts.png")} alt="icloudsoft_logo" />
                                    </div>
                                </div>
                                <div className='CategorySectionHome'>
                                    <div className='mb-4'>
                                        <img className='w-25' src={require("../Img/Category1.png")} alt="icloudsoft_logo" />
                                    </div>
                                    <h3 className='' style={{ fontSize: "30px" }}>
                                        Meet Our Creative Event Organizer
                                    </h3>
                                    <p className='' style={{ fontSize: "16px" }}>
                                        This group will plan your event in an orderly fashion.
                                    </p>
                                    <button>
                                        Learn More
                                    </button>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="row g-4">
                                    <div className="col-lg-6 col-sm-6">
                                        <div className='SingleService'>
                                            <img className='w-100 p-1' src={require("../Img/Team1.png")} alt="icloudsoft_logo" />
                                            <div className='Services-Content'>
                                                <h2>
                                                    Sanskruti
                                                </h2>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-sm-6">
                                        <div className='SingleService'>
                                            <img className='w-100 p-1' src={require("../Img/Team2.png")} alt="icloudsoft_logo" />
                                            <div className='Services-Content'>
                                                <h2>
                                                    Deepak
                                                </h2>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-sm-6">
                                        <div className='SingleService'>
                                            <img className='w-100 p-1' src={require("../Img/Team3.png")} alt="icloudsoft_logo" />
                                            <div className='Services-Content'>
                                                <h2>
                                                    Sunny
                                                </h2>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-sm-6">
                                        <div className='SingleService'>
                                            <img className='w-100 p-1' src={require("../Img/Team4.png")} alt="icloudsoft_logo" />
                                            <div className='Services-Content'>
                                                <h2>
                                                    Priyanka
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid d-md-none d-none d-lg-block" >
                    <div className="container p-5 mb-5">
                        <div className='AboutSectionHome text-center mt-3 pb-3 mb-5'>
                            <h6 className='mb-2'>
                                Introduction Video
                            </h6>
                            <h3 className='' style={{ fontSize: "50px" }}>
                                Bridely Presentation
                            </h3>
                        </div>
                        <div className=' pt-2'>
                            <iframe className='w-100 m-0' title="gmap" style={{ height: "450px", borderRadius: "50px 0px 50px 0px" }} src="https://www.youtube.com/embed/XHOmBV4js_E?feature=oembed&start&end&wmode=opaque&loop=0&controls=0&mute=0&rel=0&modestbranding=0&autoplay=1">
                            </iframe>
                        </div>
                    </div>
                </div>

                <div className="container-fluid d-lg-none d-md-block" >
                    <div className="container p-3">
                        <div className='AboutSectionHome text-center mt-3 pb-3 mb-3'>
                            <h6 className='mb-2'>
                                Introduction Video
                            </h6>
                            <h3 className='mt-3' style={{ fontSize: "30px" }}>
                                Bridely Presentation
                            </h3>
                        </div>
                        <div className='mt-2'>
                            <iframe className='w-100 m-0' title="gmap" style={{ height: "200px", borderRadius: "50px 0px 50px 0px" }} src="https://www.youtube.com/embed/XHOmBV4js_E?feature=oembed&start&end&wmode=opaque&loop=0&controls=0&mute=0&rel=0&modestbranding=0&autoplay=1">
                            </iframe>
                        </div>
                    </div>
                </div>
            </section>
            

        </>
    )
}

export default Team