import React, { useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import Navbar from './Navbar'
import "./Home.css"

const Home = () => {

    const form = useRef();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [number, setNumber] = useState("");
    const [date, setDate] = useState("");
    const [massage, setMassage] = useState("");
    const [errors, setErrors] = useState({});


    const loginHandle = (e) => {
        e.preventDefault();

        const errors = validateForm();

        if (Object.keys(errors).length === 0) {
            errors.name = ""; errors.email = ""; errors.number = ""; errors.date = ""; errors.massage = "";

            var url = "https://wa.me/1271379270?text="
                + " Name : " + name + " Email : " + email + " Guests : " + number + " Date : " + date + " Content : " + massage;

            window.open(url, '_blank').focus();
        }

        else {
            setErrors(errors);
        }
        setName("");
        setEmail("");
        setNumber("");
        setDate("");
        setMassage("");

    }


    const validateForm = () => {
        const errors = {};
        // console.log(errors)
        var mailformat = /\S+@\S+\.\S+/;

        if (!name) {
            errors.name = "Names Is Requried";
        }
        else if (name.length < 4) {
            errors.name = "Names Requried At Least Four Characters"
        }
        if (!email) {
            errors.email = "Email Is Requried";
        }
        if (email) {
            if (email.match(mailformat)) {

            }
            else {
                errors.email = `Email Must Be In " Text + @ + Text + . + Text " Format`
            }
        }

        if (!massage) {
            errors.massage = "Massage Is Requried";
        }
        return errors;

    }

    return (
        <>

            <section>
                <div className='container-fluid p-0 HomeContentMain d-sm-none d-none d-md-block MainImg' style={{ backgroundImage: `url(${require("../Img/Main.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'top' }}>
                    <Navbar />
                    <div className="container mt-5 pt-5">
                        <div className="row d-flex justify-content-center align-items-center g-4 ">

                            <div className="col-lg-5 col-md-6">
                                <div className='HomeSeT'>
                                    <img className='' src={require("../Img/HomeSeT1.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <div className='HomeSeTcontent'>
                                    <div className='mb-4'>
                                        <img className='' src={require("../Img/HomeSeT2.png")} alt="icloudsoft_logo" />
                                    </div>
                                    <h2>Perfect Weddings <br /> Made Easy!</h2>
                                    <p>
                                        Easy Planning & Reservations at Our Best Prices Promised.
                                    </p>
                                    <div className=''>
                                        <NavLink className='' to='/contactus' >

                                            <button className=''>
                                                <span><i class="fa-solid fa-clipboard-list me-1"></i></span>
                                                Make Reservations
                                            </button>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container p-4 pt-lg-5">
                            <div className="row g-4 d-flex justify-content-between align-items-center pt-lg-5">
                                <div className="col-lg-5 col-md-5">
                                    <div className='AboutSectionHome'>
                                        <h6>
                                            About Bridely
                                        </h6>
                                        <h3>
                                            What We do, We do With Passion
                                        </h3>
                                        <p>
                                            Everything entirely customizable that you require for a stress-free wedding
                                        </p>
                                        <button>
                                            Learn More
                                        </button>
                                    </div>
                                </div>

                                <div className='col-lg-1 col-md-1'>
                                    <div className='birdUp'>
                                        <div className='birdDown' >
                                            <img className='' src={require("../Img/bird.png")} alt="icloudsoft_logo" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-4">

                                    <div className='HomeSet2-1 position-relative'>
                                        <img className='' src={require("../Img/HomeSeT2-1.png")} alt="icloudsoft_logo" />

                                        <div className='HomeSet2-2 position-absolute' style={{ bottom: "-10%", left: "-25%", top: "70%" }}>
                                            <img className='' src={require("../Img/HomeSeT2-2.png")} alt="icloudsoft_logo" />
                                        </div>
                                    </div>

                                    <div className='HomeSet2-3 text-end '>
                                        <img className='' src={require("../Img/HomeSeT2-3.png")} alt="icloudsoft_logo" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='container-fluid p-0 HomeContentMain d-md-none d-sm-block' >
                    <Navbar />
                    <div className="container p-3">
                        <div className="row d-flex justify-content-center align-items-center g-4 ">
                            <div className="col-lg-6 col-md-5">
                                <div className='HomeSeT'>
                                    <img className='w-100' src={require("../Img/HomeSeT1.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-5">
                                <div className='HomeSeTcontent text-center playfair-display p-3'>
                                    <div className='mb-4'>
                                        <img className='' src={require("../Img/HomeSeT2.png")} alt="icloudsoft_logo" />
                                    </div>
                                    <h2 className='' style={{ fontSize: "30px" }}>Perfect Weddings Made Easy!</h2>
                                    <p className='' style={{ fontSize: "16px" }}>
                                        Easy Planning & Reservations at Our Best Prices Promised.
                                    </p>
                                    <div className=''>
                                        <NavLink className='' to='/contactus' >

                                            <button className=''>
                                                <span><i class="fa-solid fa-clipboard-list me-1"></i></span>
                                                Make Reservations
                                            </button>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container p-4">
                        <div className="row g-4 d-flex justify-content-between align-items-center">
                            <div className="col-lg-5 col-md-5 p-4">
                                <div className='HomeSet2-1 position-relative '>
                                    <img className='w-100' src={require("../Img/HomeSeT2-1.png")} alt="icloudsoft_logo" />

                                    <div className='HomeSet2-2 position-absolute' style={{ bottom: "-10%", left: "-8%", top: "74%" }}>
                                        <img className='' src={require("../Img/HomeSeT2-2.png")} alt="icloudsoft_logo" />
                                    </div>
                                </div>

                                <div className='HomeSet2-3 text-end '>
                                    <img className='w-50' style={{ width: "50%" }} src={require("../Img/HomeSeT2-3.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>

                            <div className="col-lg-5 col-md-5">
                                <div className='AboutSectionHome text-center'>
                                    <h6>
                                        About Bridely
                                    </h6>
                                    <h3>
                                        What We do, We do With Passion
                                    </h3>
                                    <p>
                                        Everything entirely customizable that you require for a stress-free wedding
                                    </p>
                                    <button>
                                        Learn More
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section>
                <div className="container-fluid" >
                    <div className="container p-4">
                        <div className="row g-4 d-flex justify-content-between align-items-center">
                            <div className="col-lg-5">
                                <div className='AboutSectionHome'>
                                    <h6>
                                        About Bridely
                                    </h6>
                                    <h3>
                                        What We do, We do With Passion
                                    </h3>
                                    <p>
Everything entirely customizable that you require for a stress-free wedding
                                    </p>
                                    <button>
                                        Learn More
                                    </button>
                                </div>
                            </div>

                            <div className="col-lg-5">
                                <div className='HomeSet2-1 position-relative'>
                                    <img className='w-100' src={require("../Img/HomeSeT2-1.png")} alt="icloudsoft_logo" />

                                    <div className='HomeSet2-2 position-absolute' style={{ bottom: "-10%", left: "-8%", top: "74%" }}>
                                        <img className='' src={require("../Img/HomeSeT2-2.png")} alt="icloudsoft_logo" />
                                    </div>
                                </div>

                                <div className='HomeSet2-3 text-end '>
                                    <img className='' style={{ width: "180px" }} src={require("../Img/HomeSeT2-3.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <section>
                <div className="container-fluid d-md-none d-none d-lg-block" >
                    <div className="container p-5">
                        <div className='mt-5 pt-5'>
                            <iframe className='w-100 m-0' title="gmap" style={{ height: "450px", borderRadius: "50px 0px 50px 0px" }} src="https://www.youtube.com/embed/XHOmBV4js_E?feature=oembed&start&end&wmode=opaque&loop=0&controls=0&mute=0&rel=0&modestbranding=0&autoplay=1">
                            </iframe>
                        </div>
                    </div>
                </div>

                <div className="container-fluid d-lg-none d-md-block" >
                    <div className="container p-3">
                        <div className='mt-2'>
                            <iframe className='w-100 m-0' title="gmap" style={{ height: "200px", borderRadius: "50px 0px 50px 0px" }} src="https://www.youtube.com/embed/XHOmBV4js_E?feature=oembed&start&end&wmode=opaque&loop=0&controls=0&mute=0&rel=0&modestbranding=0&autoplay=1">
                            </iframe>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid d-md-none d-none d-lg-block" style={{ backgroundImage: `url(${require("../Img/CategoryBg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "auto", backgroundPosition: 'center', backgroundColor: "#EDF7F8" }}>
                    <div className="container p-5">
                        <div className="row g-4 d-flex justify-content-around pt-5 align-items-center ">
                            <div className="col-lg-6">
                                <div className=' HeartsUp'>
                                    <div className='mb-4  HeartsDown' >
                                        <img className='w-100' src={require("../Img/hearts.png")} alt="icloudsoft_logo" />
                                    </div>
                                </div>

                                <div className='CategorySectionHome text-center'>
                                    <div className='mb-4'>
                                        <img className='' src={require("../Img/Category1.png")} alt="icloudsoft_logo" />
                                    </div>
                                    <h3>
                                        Includes Various Product Categories
                                    </h3>
                                    <p>
                                        Everything you require for your occasion is here.
                                    </p>
                                    <button>
                                        Learn More
                                    </button>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="row g-4">
                                    <div className="col-lg-6">
                                        <div className='SingleService'>
                                            <img className='w-100 p-1' src={require("../Img/Category2.png")} alt="icloudsoft_logo" />
                                            <div className='Services-Content'>
                                                <h2>
                                                    Wedding Walls
                                                </h2>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className='SingleService'>
                                            <img className='w-100 p-1' src={require("../Img/Category3.png")} alt="icloudsoft_logo" />
                                            <div className='Services-Content'>
                                                <h2>
                                                    Metal Circles & Flowers
                                                </h2>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className='SingleService'>
                                            <img className='w-100 p-1' src={require("../Img/Category4.png")} alt="icloudsoft_logo" />
                                            <div className='Services-Content'>
                                                <h2>
                                                    Chairs
                                                </h2>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className='SingleService'>
                                            <img className='w-100 p-1' src={require("../Img/Category5.png")} alt="icloudsoft_logo" />
                                            <div className='Services-Content'>
                                                <h2>
                                                    Catering
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid d-lg-none d-md-block" style={{ backgroundImage: `url(${require("../Img/CategoryBg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "auto", backgroundPosition: 'center', backgroundColor: "#EDF7F8" }}>
                    <div className="container p-3">
                        <div className="row g-4 d-flex justify-content-center pt-5 pb-5 align-items-center text-center">
                            <div className="col-lg-6 col-md-6">
                                <div className='HeartsUp'>
                                    <div className=' HeartsDown' >
                                        <img className='' src={require("../Img/hearts.png")} alt="icloudsoft_logo" />
                                    </div>
                                </div>
                                <div className='CategorySectionHome'>
                                    <div className='mb-4'>
                                        <img className='' src={require("../Img/Category1.png")} alt="icloudsoft_logo" />
                                    </div>
                                    <h3 className='' style={{ fontSize: "30px" }}>
                                        Includes Various Product Categories
                                    </h3>
                                    <p className='' style={{ fontSize: "16px" }}>
                                        Everything you require for your occasion is here.
                                    </p>
                                    <button>
                                        Learn More
                                    </button>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="row g-4">
                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='SingleService'>
                                            <img className='w-100 p-1' src={require("../Img/Category2.png")} alt="icloudsoft_logo" />
                                            <div className='Services-Content'>
                                                <h2>
                                                    Wedding Walls
                                                </h2>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='SingleService'>
                                            <img className='w-100 p-1' src={require("../Img/Category3.png")} alt="icloudsoft_logo" />
                                            <div className='Services-Content'>
                                                <h2>
                                                    Metal Circles & Flowers
                                                </h2>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='SingleService'>
                                            <img className='w-100 p-1' src={require("../Img/Category4.png")} alt="icloudsoft_logo" />
                                            <div className='Services-Content'>
                                                <h2>
                                                    Chairs
                                                </h2>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='SingleService'>
                                            <img className='w-100 p-1' src={require("../Img/Category5.png")} alt="icloudsoft_logo" />
                                            <div className='Services-Content'>
                                                <h2>
                                                    Catering
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid d-md-none d-none d-lg-block" style={{ backgroundImage: `url(${require("../Img/section5_backgroud.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "1000px auto", backgroundPosition: 'right' }}>
                    <div className="container p-5">
                        <div className='SectionFive mt-5'>
                            <div className='mb-4'>
                                <img className='' src={require("../Img/15-year-exp.png")} alt="icloudsoft_logo" />
                            </div>
                            <h2>
                                The Unrivaled Scenery, Unforgettable Wedding Program
                            </h2>
                        </div>
                        <div className="row g-4 d-flex justify-content-center pt-5 align-items-center text-center">
                            <div className="col-lg-7">
                                <div className='SectionFiveFirst'>
                                    <img className='w-100' src={require("../Img/section5_1.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>

                            <div className="col-lg-5">
                                <div className="row g-4">
                                    <div className="col-lg-12">
                                        <div className='SectionFiveSecond'>
                                            <img className='w-100' src={require("../Img/section5_2.png")} alt="icloudsoft_logo" />
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <div className='SectionFiveThird'>
                                            <img className='w-100' src={require("../Img/section5_3.png")} alt="icloudsoft_logo" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='d-flex justify-content-center '>
                            <div className='CategorySectionHome mb-5 pt-5'>
                                <button>
                                    Make Reservations
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid d-lg-none d-md-block" style={{ backgroundImage: `url(${require("../Img/section5_backgroud.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: " auto", backgroundPosition: 'right' }}>
                    <div className="container p-3">
                        <div className='SectionFive mt-5'>
                            <div className='mb-4'>
                                <img className='w-25' src={require("../Img/15-year-exp.png")} alt="icloudsoft_logo" />
                            </div>
                            <h2 className='' style={{ fontSize: "20px", lineHeight: "25px" }}>
                                The Unrivaled Scenery, Unforgettable Wedding Program
                            </h2>
                        </div>
                        <div className="row g-4 d-flex justify-content-center pt-5 align-items-center text-center">
                            <div className="col-lg-7 col-md-7 col-sm-7">
                                <div className='SectionFiveFirst'>
                                    <img className='w-100' src={require("../Img/section5_1.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>

                            <div className="col-lg-5 col-md-5 col-sm-5">
                                <div className="row g-4">
                                    <div className="col-lg-12">
                                        <div className='SectionFiveSecond'>
                                            <img className='w-100' src={require("../Img/section5_2.png")} alt="icloudsoft_logo" />
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <div className='SectionFiveThird'>
                                            <img className='w-100' src={require("../Img/section5_3.png")} alt="icloudsoft_logo" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='d-flex justify-content-center '>
                            <div className='CategorySectionHome mb-5 pt-5'>
                                <button>
                                    Make Reservations
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid d-md-none d-none d-lg-block" >
                    <div className="container p-5">
                        <div className="row g-4 d-flex justify-content-center align-items-center text-center">
                            <div className="col-lg-6">
                                <div className="row">
                                    <div className="col-lg-6 p-0" style={{ border: "1px solid #9ACCC9" }}>
                                        <div className='ServiceBox'>
                                            <img className='' style={{ height: "90px" }} src={require("../Img/NextEvent1.png")} alt="icloudsoft_logo" />
                                            <h3>
                                                Enteraintment
                                            </h3>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 p-0" style={{ border: "1px solid #9ACCC9" }}>
                                        <div className='ServiceBox'>
                                            <img className='' style={{ height: "90px" }} src={require("../Img/NextEvent2.png")} alt="icloudsoft_logo" />
                                            <h3>
                                                Dining
                                            </h3>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 p-0" style={{ border: "1px solid #9ACCC9" }}>
                                        <div className='ServiceBox'>
                                            <img className='' style={{ height: "90px" }} src={require("../Img/NextEvent3.png")} alt="icloudsoft_logo" />
                                            <h3>
                                                Decor
                                            </h3>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 p-0" style={{ border: "1px solid #9ACCC9" }}>
                                        <div className='ServiceBox'>
                                            <img className='' style={{ height: "90px" }} src={require("../Img/NextEvent4.png")} alt="icloudsoft_logo" />
                                            <h3>
                                                Swag & Gifting
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className='CategorySectionHome'>
                                    <div className='mb-4'>
                                        <img className='' src={require("../Img/Service1.png")} alt="icloudsoft_logo" />
                                    </div>
                                    <h3>
                                        Let’s Plan Your Next Event Together
                                    </h3>
                                    <p>
                                        From the start of your event to its conclusion, we will handle everything
                                    </p>
                                    <button>
                                        Learn More
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid d-lg-none d-md-block" >
                    <div className="container p-3">
                        <div className="row g-4 d-flex justify-content-center align-items-center text-center">
                            <div className="col-lg-6">
                                <div className="row">
                                    <div className="col-lg-6 col-6 p-0" style={{ border: "1px solid #9ACCC9" }}>
                                        <div className='ServiceBox'>
                                            <img className='' style={{ height: "90px" }} src={require("../Img/NextEvent1.png")} alt="icloudsoft_logo" />
                                            <h3>
                                                Enteraintment
                                            </h3>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-6 p-0" style={{ border: "1px solid #9ACCC9" }}>
                                        <div className='ServiceBox'>
                                            <img className='' style={{ height: "90px" }} src={require("../Img/NextEvent2.png")} alt="icloudsoft_logo" />
                                            <h3>
                                                Dining
                                            </h3>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-6 p-0" style={{ border: "1px solid #9ACCC9" }}>
                                        <div className='ServiceBox'>
                                            <img className='' style={{ height: "90px" }} src={require("../Img/NextEvent3.png")} alt="icloudsoft_logo" />
                                            <h3>
                                                Decor
                                            </h3>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-6 p-0" style={{ border: "1px solid #9ACCC9" }}>
                                        <div className='ServiceBox'>
                                            <img className='' style={{ height: "90px" }} src={require("../Img/NextEvent4.png")} alt="icloudsoft_logo" />
                                            <h3>
                                                Swag & Gifting
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className='CategorySectionHome'>
                                    <div className='mb-4 mt-5'>
                                        <img className='w-25' src={require("../Img/Service1.png")} alt="icloudsoft_logo" />
                                    </div>
                                    <h3 className='' style={{ fontSize: "30px" }}>
                                        Let’s Plan Your Next Event Together
                                    </h3>
                                    <p className='' style={{ fontSize: "16px" }}>
                                        From the start of your event to its conclusion, we will handle everything
                                    </p>
                                    <button>
                                        Learn More
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid d-md-none d-none d-lg-block" style={{ backgroundImage: `url(${require("../Img/CategoryBg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "auto", backgroundPosition: 'center', backgroundColor: "#EDF7F8" }}>
                    <div className="container p-5">
                        <div className="row g-4 d-flex justify-content-center pt-5 align-items-center text-center">
                            <div className="col-lg-6">
                                <div className=' HeartsUp'>
                                    <div className='mb-4  HeartsDown' >
                                        <img className='w-100' src={require("../Img/hearts.png")} alt="icloudsoft_logo" />
                                    </div>
                                </div>
                                <div className='CategorySectionHome'>
                                    <div className='mb-4'>
                                        <img className='' src={require("../Img/Category1.png")} alt="icloudsoft_logo" />
                                    </div>
                                    <h3>
                                        Meet Our Creative Event Organizer
                                    </h3>
                                    <p>
                                        This group will plan your event in an orderly fashion.
                                    </p>
                                    <button>
                                        Learn More
                                    </button>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="row g-4">
                                    <div className="col-lg-6">
                                        <div className='SingleService'>
                                            <img className='w-100 p-1' src={require("../Img/Team1.png")} alt="icloudsoft_logo" />
                                            <div className='Services-Content'>
                                                <h2>
                                                    Sanskruti
                                                </h2>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className='SingleService'>
                                            <img className='w-100 p-1' src={require("../Img/Team2.png")} alt="icloudsoft_logo" />
                                            <div className='Services-Content'>
                                                <h2>
                                                    Deepak
                                                </h2>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className='SingleService'>
                                            <img className='w-100 p-1' src={require("../Img/Team3.png")} alt="icloudsoft_logo" />
                                            <div className='Services-Content'>
                                                <h2>
                                                    Sunny
                                                </h2>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className='SingleService'>
                                            <img className='w-100 p-1' src={require("../Img/Team4.png")} alt="icloudsoft_logo" />
                                            <div className='Services-Content'>
                                                <h2>
                                                    Priyanka
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid d-lg-none d-md-block" style={{ backgroundImage: `url(${require("../Img/CategoryBg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "auto", backgroundPosition: 'center', backgroundColor: "#EDF7F8" }}>
                    <div className="container p-3">
                        <div className="row g-4 d-flex justify-content-center pt-5 align-items-center text-center">
                            <div className="col-lg-6">
                                <div className='HeartsUp'>
                                    <div className=' HeartsDown' >
                                        <img className='' src={require("../Img/hearts.png")} alt="icloudsoft_logo" />
                                    </div>
                                </div>
                                <div className='CategorySectionHome'>
                                    <div className='mb-4'>
                                        <img className='w-25' src={require("../Img/Category1.png")} alt="icloudsoft_logo" />
                                    </div>
                                    <h3 className='' style={{ fontSize: "30px" }}>
                                        Meet Our Creative Event Organizer
                                    </h3>
                                    <p className='' style={{ fontSize: "16px" }}>
                                        This group will plan your event in an orderly fashion.
                                    </p>
                                    <button>
                                        Learn More
                                    </button>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="row g-4">
                                    <div className="col-lg-6 col-sm-6">
                                        <div className='SingleService'>
                                            <img className='w-100 p-1' src={require("../Img/Team1.png")} alt="icloudsoft_logo" />
                                            <div className='Services-Content'>
                                                <h2>
                                                    Sanskruti
                                                </h2>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-sm-6">
                                        <div className='SingleService'>
                                            <img className='w-100 p-1' src={require("../Img/Team2.png")} alt="icloudsoft_logo" />
                                            <div className='Services-Content'>
                                                <h2>
                                                    Deepak
                                                </h2>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-sm-6">
                                        <div className='SingleService'>
                                            <img className='w-100 p-1' src={require("../Img/Team3.png")} alt="icloudsoft_logo" />
                                            <div className='Services-Content'>
                                                <h2>
                                                    Sunny
                                                </h2>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-sm-6">
                                        <div className='SingleService'>
                                            <img className='w-100 p-1' src={require("../Img/Team4.png")} alt="icloudsoft_logo" />
                                            <div className='Services-Content'>
                                                <h2>
                                                    Priyanka
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid d-md-none d-none d-lg-block" >
                    <div className="container p-5 pb-5 mb-5">
                        <div className="row g-4 d-flex justify-content-center pt-5 pb-5 mb-5 mt-5 align-items-center text-center">
                            <div className="col-lg-5 col-md-4">
                                <div className='position-relative'>
                                    <div className='story-section position-absolute' style={{ top: "-130px", left: "3%", width: "600px" }}>
                                        <div className='birdUpSecond'>
                                            <div className='birdDownSecond' >
                                                <img className='' src={require("../Img/bird.png")} alt="icloudsoft_logo" />
                                            </div>
                                        </div>
                                        <div className='mb-4'>
                                            <img className='' src={require("../Img/quote_icon.png")} alt="icloudsoft_logo" />
                                        </div>
                                        <h3>
                                            Marry Jain & Jamie Foster
                                        </h3>
                                        <p>
                                            Bridely truly exceeded all of my expectations as a wedding planner.They took the time to understand my vision and preferences, and flawlessly executed every detail. I was able to relax and enjoy every moment of my wedding day
                                        </p>
                                        <button>
                                            See the Story
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-8">
                                <div className='story-section-img '>
                                    <img className='w-100 ' src={require("../Img/story-section-img.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid d-lg-none d-md-block" >
                    <div className="container p-3">
                        <div className="row g-4 d-flex justify-content-center mb-3 mt-3 align-items-center text-center">
                            <div className="col-lg-6 col-md-6">
                                <div className='story-section-img '>
                                    <img className='w-100 ' src={require("../Img/story-section-img.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <div className=''>
                                    <div className='story-section p-3 ' >
                                        <div className='mb-4'>
                                            <img className='' src={require("../Img/quote_icon.png")} alt="icloudsoft_logo" />
                                        </div>
                                        <h3 className='' style={{ fontSize: "25px" }}>
                                            Marry Jain & Jamie Foster
                                        </h3>
                                        <p className='' style={{ fontSize: "14px" }}>
                                            Bridely truly exceeded all of my expectations as a wedding planner.They took the time to understand my vision and preferences, and flawlessly executed every detail. I was able to relax and enjoy every moment of my wedding day
                                        </p>
                                        <button>
                                            See the Story
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid d-md-none d-none d-lg-block" style={{ backgroundImage: `url(${require("../Img/CategoryBg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "auto", backgroundPosition: 'right', backgroundColor: "#EDF7F8" }}>
                    <div className="container p-5">
                        <div className="row g-4 d-flex justify-content-around pt-5 pb-4 align-items-center text-center">
                            <div className="col-lg-6">
                                <div className='ReservedSection'>
                                    <div className=''>
                                        <img className='' style={{ width: "95%" }} src={require("../Img/reserved_sec9.png")} alt="icloudsoft_logo" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className='CategorySectionHome'>
                                    <div className=' HeartsUpSecond'>
                                        <div className='mb-4  HeartsDownSecond' >
                                            <img className='w-100' src={require("../Img/hearts.png")} alt="icloudsoft_logo" />
                                        </div>
                                    </div>
                                    <div className='mb-4'>
                                        <img className='' src={require("../Img/Category1.png")} alt="icloudsoft_logo" />
                                    </div>
                                    <h3>
                                        Make Reservations
                                    </h3>
                                    <p>
                                        All set to get started? Reserve your spot today!
                                    </p>
                                    <button>
                                        Learn More
                                    </button>
                                </div>

                                <form ref={form} onSubmit={loginHandle} className="row g-4 merriweather mt-3" style={{ borderRadius: "0px 0px 0px 0px" }}>
                                    <div class="col-lg-6 ">
                                        <input type="text" placeholder='   Your Name' className="form-control text-dark" style={{ borderRadius: "100px", color: "#777", backgroundColor: "#fff", border: "1px solid #9ACCC9", height: "48px", fontSize: "12px" }} id="inputName" name="name"
                                            onChange={e => setName(e.target.value)} value={name}
                                        />
                                        {errors.name && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.name}</div>}
                                    </div>

                                    <div className='col-lg-6'>
                                        <input type="email" placeholder='  Your Email' className="form-control " style={{ borderRadius: "100px", color: "#777", backgroundColor: "#fff", border: "1px solid #9ACCC9", height: "48px", fontSize: "12px" }} id="inputEmail4" name="email"
                                            onChange={e => setEmail(e.target.value)} value={email}
                                        />
                                        {errors.email && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.email}</div>}
                                    </div>

                                    <div className='col-lg-6'>
                                        <input type="number" placeholder='  Guests' className="form-control " style={{ borderRadius: "100px", color: "#777", backgroundColor: "#fff", border: "1px solid #9ACCC9", height: "48px", fontSize: "12px" }} id="inputEmail4" name="email"
                                            onChange={e => setNumber(e.target.value)} value={number}
                                        />
                                        {errors.number && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.number}</div>}
                                    </div>

                                    <div className='col-lg-6'>
                                        <input type="date" placeholder='' className="form-control " style={{ borderRadius: "100px", color: "#777", backgroundColor: "#fff", border: "1px solid #9ACCC9", height: "48px", fontSize: "12px" }} id="inputEmail4" name="email"
                                            onChange={e => setDate(e.target.value)} value={date}
                                        />
                                        {errors.date && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.date}</div>}
                                    </div>

                                    <div className="col-lg-12">
                                        <textarea type="text" placeholder='   your message' className="form-control" style={{ borderRadius: "100px", height: "53px", backgroundColor: "#fff", border: "1px solid #9ACCC9", fontSize: "12px" }} id="inputText3" name="message"
                                            onChange={e => setMassage(e.target.value)} value={massage}
                                        />
                                        {errors.massage && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.massage}</div>}
                                    </div>

                                    <div className="col-lg-12 mt-lg-2">
                                        <div className='d-flex justify-content-center mb-3 mt-4'>
                                            <button type="submit" className="  FirstButton "  ><b className='' style={{ fontWeight: "400", fontSize: "16px" }}>Make Reservations</b></button>
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>

                    <div className="container p-5">
                        <div className="row d-flex justify-content-center align-items-center text-center ">
                            <div className="col-lg-3 p-0" style={{ border: "1px solid #D2E5E5", backgroundColor: "#fff", borderRadius: "50px 0px 0px 0px", height: "150px" }}>
                                <div className='ServiceBox p-5'>
                                    <img className='w-100' style={{ height: "3rem" }} src={require("../Img/Client1.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>

                            <div className="col-lg-3 p-0" style={{ border: "1px solid #D2E5E5", backgroundColor: "#fff", height: "150px" }}>
                                <div className='ServiceBox p-5'>
                                    <img className='' src={require("../Img/Client2.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>

                            <div className="col-lg-3 p-0" style={{ border: "1px solid #D2E5E5", backgroundColor: "#fff", height: "150px" }}>
                                <div className='ServiceBox p-5'>
                                    <img className='' src={require("../Img/Client3.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>

                            <div className="col-lg-3 p-0" style={{ border: "1px solid #D2E5E5", backgroundColor: "#fff", height: "150px" }}>
                                <div className='ServiceBox p-5'>
                                    <img className='' src={require("../Img/Client4.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>

                            <div className="col-lg-3 p-0" style={{ border: "1px solid #D2E5E5", backgroundColor: "#fff", height: "150px" }}>
                                <div className='ServiceBox p-5'>
                                    <img className='' src={require("../Img/Client5.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>

                            <div className="col-lg-3 p-0" style={{ border: "1px solid #D2E5E5", backgroundColor: "#fff", height: "150px" }}>
                                <div className='ServiceBox p-5'>
                                    <img className='' src={require("../Img/Client6.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>

                            <div className="col-lg-3 p-0" style={{ border: "1px solid #D2E5E5", backgroundColor: "#fff", height: "150px" }}>
                                <div className='ServiceBox p-5'>
                                    <img className='' src={require("../Img/Client7.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>

                            <div className="col-lg-3 p-0" style={{ border: "1px solid #D2E5E5", backgroundColor: "#fff", height: "150px", borderRadius: "0px 0px 50px 0px" }}>
                                <div className='ServiceBox p-5'>
                                    <img className='' src={require("../Img/Client8.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid d-lg-none d-md-block" style={{ backgroundImage: `url(${require("../Img/CategoryBg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "auto", backgroundPosition: 'right', backgroundColor: "#EDF7F8" }}>
                    <div className="container p-3">
                        <div className="row g-4 d-flex justify-content-around pt-5 pb-4 align-items-center text-center">
                            <div className="col-lg-6">
                                <div className='ReservedSection'>
                                    <div className=''>
                                        <img className='w-100' src={require("../Img/reserved_sec9.png")} alt="icloudsoft_logo" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className='CategorySectionHome'>
                                    <div className=' HeartsUpSecond'>
                                        <div className='mb-4  HeartsDownSecond' >
                                            <img className='' src={require("../Img/hearts.png")} alt="icloudsoft_logo" />
                                        </div>
                                    </div>
                                    <div className='mb-4'>
                                        <img className='w-25' src={require("../Img/Category1.png")} alt="icloudsoft_logo" />
                                    </div>
                                    <h3 className='' style={{ fontSize: "30px" }}>
                                        Make Reservations
                                    </h3>
                                    <p className='' style={{ fontSize: "16px" }}>
                                        All set to get started? Reserve your spot today!
                                    </p>
                                    <button>
                                        Learn More
                                    </button>
                                </div>

                                <form ref={form} onSubmit={loginHandle} className="row g-4 merriweather mt-3" style={{ borderRadius: "0px 0px 0px 0px" }}>
                                    <div class="col-lg-6 col-6">
                                        <input type="text" placeholder='   Your Name' className="form-control text-dark" style={{ borderRadius: "100px", color: "#777", backgroundColor: "#fff", border: "1px solid #9ACCC9", height: "48px", fontSize: "12px" }} id="inputName" name="name"
                                            onChange={e => setName(e.target.value)} value={name}
                                        />
                                        {errors.name && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.name}</div>}
                                    </div>

                                    <div className='col-lg-6 col-6'>
                                        <input type="email" placeholder='  Your Email' className="form-control " style={{ borderRadius: "100px", color: "#777", backgroundColor: "#fff", border: "1px solid #9ACCC9", height: "48px", fontSize: "12px" }} id="inputEmail4" name="email"
                                            onChange={e => setEmail(e.target.value)} value={email}
                                        />
                                        {errors.email && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.email}</div>}
                                    </div>

                                    <div className='col-lg-6 col-6'>
                                        <input type="number" placeholder='  Guests' className="form-control " style={{ borderRadius: "100px", color: "#777", backgroundColor: "#fff", border: "1px solid #9ACCC9", height: "48px", fontSize: "12px" }} id="inputEmail4" name="email"
                                            onChange={e => setNumber(e.target.value)} value={number}
                                        />
                                        {errors.number && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.number}</div>}
                                    </div>

                                    <div className='col-lg-6 col-6'>
                                        <input type="date" placeholder='' className="form-control " style={{ borderRadius: "100px", color: "#777", backgroundColor: "#fff", border: "1px solid #9ACCC9", height: "48px", fontSize: "12px" }} id="inputEmail4" name="email"
                                            onChange={e => setDate(e.target.value)} value={date}
                                        />
                                        {errors.date && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.date}</div>}
                                    </div>

                                    <div className="col-lg-12">
                                        <textarea type="text" placeholder='   your message' className="form-control" style={{ borderRadius: "100px", height: "53px", backgroundColor: "#fff", border: "1px solid #9ACCC9", fontSize: "12px" }} id="inputText3" name="message"
                                            onChange={e => setMassage(e.target.value)} value={massage}
                                        />
                                        {errors.massage && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.massage}</div>}
                                    </div>

                                    <div className="col-lg-12 mt-lg-2">
                                        <div className='d-flex justify-content-center mb-3 mt-3'>
                                            <button type="submit" className="  FirstButton "  ><b className='' style={{ fontWeight: "400", fontSize: "16px" }}>Make Reservations</b></button>
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>

                    <div className="container p-3">
                        <div className="row d-flex justify-content-center align-items-center text-center ">
                            <div className="col-lg-3 col-6 p-0" style={{ border: "1px solid #D2E5E5", backgroundColor: "#fff", borderRadius: "50px 0px 0px 0px", height: "130px" }}>
                                <div className='ServiceBox p-5'>
                                    <img className='' src={require("../Img/Client1.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>

                            <div className="col-lg-3 col-6 p-0" style={{ border: "1px solid #D2E5E5", backgroundColor: "#fff", height: "130px" }}>
                                <div className='ServiceBox p-5'>
                                    <img className='' src={require("../Img/Client2.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>

                            <div className="col-lg-3 col-6 p-0" style={{ border: "1px solid #D2E5E5", backgroundColor: "#fff", height: "130px" }}>
                                <div className='ServiceBox p-5'>
                                    <img className='' src={require("../Img/Client3.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>

                            <div className="col-lg-3 col-6 p-0" style={{ border: "1px solid #D2E5E5", backgroundColor: "#fff", height: "130px" }}>
                                <div className='ServiceBox p-5'>
                                    <img className='' src={require("../Img/Client4.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>

                            <div className="col-lg-3 col-6 p-0" style={{ border: "1px solid #D2E5E5", backgroundColor: "#fff", height: "130px" }}>
                                <div className='ServiceBox p-5'>
                                    <img className='' src={require("../Img/Client5.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>

                            <div className="col-lg-3 col-6 p-0" style={{ border: "1px solid #D2E5E5", backgroundColor: "#fff", height: "130px" }}>
                                <div className='ServiceBox p-5'>
                                    <img className='' src={require("../Img/Client6.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>

                            <div className="col-lg-3 col-6 p-0" style={{ border: "1px solid #D2E5E5", backgroundColor: "#fff", height: "130px" }}>
                                <div className='ServiceBox p-5'>
                                    <img className='' src={require("../Img/Client7.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>

                            <div className="col-lg-3 col-6 p-0" style={{ border: "1px solid #D2E5E5", backgroundColor: "#fff", height: "130px", borderRadius: "0px 0px 50px 0px" }}>
                                <div className='ServiceBox p-5'>
                                    <img className='' src={require("../Img/Client8.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid d-sm-none d-none d-md-block" style={{ backgroundImage: `url(${require("../Img/InstagramBg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "contain", height: "1300px", backgroundPosition: 'bottom' }}>
                    <div className="container p-5 text-center" >
                        <div className='d-flex justify-content-center text-center' >
                            <div className='mb-4 mt-4'>
                                <img className='' src={require("../Img/instagram_icon.png")} alt="icloudsoft_logo" />
                            </div>
                            <div className='BirdUpThird '>
                                <div className='BirdDownThird' >
                                    <img className='' src={require("../Img/bird.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>
                        </div>
                        <h3 className='fs-1 playfair-display'>
                            Instagram Feed
                        </h3>
                        <div className="row g-4 d-flex justify-content-around pt-5  align-items-center text-center">
                            <div className="col-xl-3 col-lg-6 col-md-6">
                                <div className='CommonImg'>
                                    <img className='' src={require("../Img/FooterTop1.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-6 col-md-6">
                                <div className='CommonImg mt-5'>
                                    <img className='' src={require("../Img/FooterTop2.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-6 col-md-6">
                                <div className='CommonImg'>
                                    <img className='' src={require("../Img/FooterTop3.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-6 col-md-6">
                                <div className='CommonImg mt-5'>
                                    <img className='' src={require("../Img/FooterTop4.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

                <div className="container-fluid d-md-none d-sm-block CoupleImg" style={{ backgroundImage: `url(${require("../Img/InstagramBg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "contain", backgroundPosition: 'bottom' }}>
                    <div className="container p-3 text-center" >
                        <div className='d-flex justify-content-center text-center' >
                            <div className='mb-4 mt-4'>
                                <img className='' src={require("../Img/instagram_icon.png")} alt="icloudsoft_logo" />
                            </div>
                            <div className='BirdUpThird '>
                                <div className='BirdDownThird' >
                                    <img className='' src={require("../Img/bird.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>
                        </div>
                        <h3 className='fs-1 playfair-display'>
                            Instagram Feed
                        </h3>
                        <div className="row g-4 d-flex justify-content-around pt-4  align-items-center text-center">
                            <div className="col-lg-3 col-6">
                                <div className='CommonImg'>
                                    <img className='w-100' src={require("../Img/FooterTop1.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>

                            <div className="col-lg-3 col-6">
                                <div className='CommonImg '>
                                    <img className='w-100' src={require("../Img/FooterTop2.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>

                            <div className="col-lg-3 col-6">
                                <div className='CommonImg'>
                                    <img className='w-100' src={require("../Img/FooterTop3.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>

                            <div className="col-lg-3 col-6">
                                <div className='CommonImg'>
                                    <img className='w-100' src={require("../Img/FooterTop4.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Home