import React from 'react'
import "./Silder.css"

const Silder = () => {
    return (
        <section>
            <div className="container-fluid " style={{ backgroundColor: "#F0F7F7" }}>
                <div className="container  p-5  d-md-none d-none d-lg-block">
                    <div className='BirdUpThirdTestimonial '>
                        <div className='BirdDownThirdTestimonial' >
                            <img className='' src={require("../Img/bird1.png")} alt="icloudsoft_logo" />
                        </div>
                    </div>
                    <div className='AboutSectionHome text-center mt-3 pb-3 mb-5'>
                        <h6 className='mb-2'>
                            Hear From Our Newlyweds
                        </h6>
                        <h3 className='' style={{ fontSize: "50px" }}>
                            Love Stories Shared by Our Clients
                        </h3>
                    </div>

                    <div className="testimonials w-100 MainTestimonial">
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                            <span className='LeftArrow'>
                                <i className="fa-solid fa-arrow-left"></i>
                            </span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                            <span className='RightArrow'>
                                <i className="fa-solid fa-arrow-right"></i>
                            </span>
                            <span className="visually-hidden">Next</span>
                        </button>
                        <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                            <div className="carousel-inner ">
                                <div className="carousel-item active">
                                    <div className="single-item">
                                        <div className="row g-4 d-flex justify-content-center align-items-center" >
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 ">
                                                <div className="">
                                                    <img className='' style={{ width: "235px", height: "100%", borderRadius: "10px" }} src={require("../Img/testimonial1.png")} alt="" />
                                                </div>
                                            </div>

                                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8">
                                                <div className='ClientReview'>
                                                    <p>
                                                        I recently had the pleasure of working with Bridely for my wedding, and I can confidently say that they exceeded all my expectations! From our very first consultation, it was clear that our wedding planner, Emily, was not only professional but also genuinely passionate about making our day perfect.
                                                    </p>
                                                    <h3 className=''>
                                                        David Sullivan
                                                    </h3>
                                                    <h6>
                                                        Event Planning
                                                    </h6>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="carousel-item">
                                    <div className="single-item">
                                        <div className="row g-4 d-flex justify-content-center align-items-center" >
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 ">
                                                <div className="">
                                                    <img className='' style={{ width: "235px", height: "100%", borderRadius: "10px" }} src={require("../Img/testimonial1.png")} alt="" />
                                                </div>
                                            </div>

                                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8">
                                                <div className='ClientReview'>
                                                    <p>
                                                        If you're in the market for a wedding planner, I cannot recommend Bridely enough! Their professionalism, creativity, and dedication to making our day special were truly exceptional. Thanks to Emily and the entire Bridely team, our wedding turned into the fairytale we always dreamed of. If you want a stress-free planning experience and a beautiful wedding that reflects your personality, Bridely is the way to go!
                                                    </p>

                                                    <h3 className=''>
                                                        Cordia Wehner
                                                    </h3>
                                                    <h6>
                                                        Event Planning
                                                    </h6>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div className="row g-4 d-flex justify-content-center text-center align-items-center row-cols-lg-5 row-cols-md-3 row-cols-sm-2 row-cols-2 mt-5 pt-5 mb-5 pb-5">
                        <div>
                            <img className='' src={require("../Img/SilderClient1.png")} alt="icloudsoft_logo" />
                        </div>

                        <div>
                            <img className='' src={require("../Img/SilderClient2.png")} alt="icloudsoft_logo" />
                        </div>

                        <div>
                            <img className='' src={require("../Img/SilderClient3.png")} alt="icloudsoft_logo" />
                        </div>

                        <div>
                            <img className='' src={require("../Img/SilderClient4.png")} alt="icloudsoft_logo" />
                        </div>

                        <div>
                            <img className='' src={require("../Img/SilderClient5.png")} alt="icloudsoft_logo" />
                        </div>
                    </div>
                </div>

                <div className="container  p-2 d-lg-none d-md-block">
                    <div className='AboutSectionHome text-center mt-3 pb-3 '>
                        <h6 className='mb-2'>
                            Hear From Our Newlyweds
                        </h6>
                        <h3 className='' style={{ fontSize: "30px" }}>
                            Love Stories Shared by Our Clients
                        </h3>
                    </div>

                    <div className="testimonials w-100 MainTestimonial">
                        <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <div className="single-item">
                                        <div className="row g-4 d-flex justify-content-center align-items-center" >
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 ">
                                                <div className="">
                                                    <img className='' style={{ width: "235px", height: "100%", borderRadius: "10px" }} src={require("../Img/testimonial1.png")} alt="" />
                                                </div>
                                            </div>

                                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                                                <div className='ClientReview'>
                                                    <p>
                                                        I recently had the pleasure of working with Bridely for my wedding, and I can confidently say that they exceeded all my expectations! From our very first consultation, it was clear that our wedding planner, Emily, was not only professional but also genuinely passionate about making our day perfect.
                                                    </p>
                                                    <h3 className=''>
                                                        David Sullivan
                                                    </h3>
                                                    <h6>
                                                        Event Planning
                                                    </h6>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="carousel-item">
                                    <div className="single-item">
                                        <div className="row g-4 d-flex justify-content-center align-items-center" >
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 ">
                                                <div className="">
                                                    <img className='' style={{ width: "235px", height: "100%", borderRadius: "10px" }} src={require("../Img/testimonial1.png")} alt="" />
                                                </div>
                                            </div>

                                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                                                <div className='ClientReview'>
                                                    <p>
                                                        If you're in the market for a wedding planner, I cannot recommend Bridely enough! Their professionalism, creativity, and dedication to making our day special were truly exceptional. Thanks to Emily and the entire Bridely team, our wedding turned into the fairytale we always dreamed of. If you want a stress-free planning experience and a beautiful wedding that reflects your personality, Bridely is the way to go!
                                                    </p>
                                                    <h3 className=''>
                                                        Cordia Wehner
                                                    </h3>
                                                    <h6>
                                                        Event Planning
                                                    </h6>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                            <span className='LeftArrow'>
                                <i className="fa-solid fa-arrow-left"></i>
                            </span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                            <span className='RightArrow'>
                                <i className="fa-solid fa-arrow-right"></i>
                            </span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>

                    <div className="row g-4 d-flex justify-content-center text-center align-items-center row-cols-lg-5 row-cols-md-3 row-cols-sm-2 row-cols-2 mt-5 pt-5 mb-5 pb-5">
                        <div>
                            <img className='' src={require("../Img/SilderClient1.png")} alt="icloudsoft_logo" />
                        </div>

                        <div>
                            <img className='' src={require("../Img/SilderClient2.png")} alt="icloudsoft_logo" />
                        </div>

                        <div>
                            <img className='' src={require("../Img/SilderClient3.png")} alt="icloudsoft_logo" />
                        </div>

                        <div>
                            <img className='' src={require("../Img/SilderClient4.png")} alt="icloudsoft_logo" />
                        </div>

                        <div>
                            <img className='' src={require("../Img/SilderClient5.png")} alt="icloudsoft_logo" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Silder