import React, { useRef, useState } from 'react'
import "./Contact.css"
import Navbar from './Navbar'
import { NavLink } from 'react-router-dom'

const Contact = () => {

    const form = useRef();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [number, setNumber] = useState("");
    const [date, setDate] = useState("");
    const [massage, setMassage] = useState("");
    const [errors, setErrors] = useState({});


    const loginHandle = (e) => {
        e.preventDefault();

        const errors = validateForm();

        if (Object.keys(errors).length === 0) {
            errors.name = ""; errors.email = ""; errors.number = ""; errors.date = ""; errors.massage = "";

            var url = "https://wa.me/1271379270?text="
                + " Name : " + name + " Email : " + email + " Phone : " + number + " Date : " + date + " Message : " + massage;

            window.open(url, '_blank').focus();
        }

        else {
            setErrors(errors);
        }
        setName("");
        setEmail("");
        setNumber("");
        setDate("");
        setMassage("");

    }


    const validateForm = () => {
        const errors = {};
        // console.log(errors)
        var mailformat = /\S+@\S+\.\S+/;

        if (!name) {
            errors.name = "Names Is Requried";
        }
        else if (name.length < 4) {
            errors.name = "Names Requried At Least Four Characters"
        }

        if (!number) {
            errors.number = "Phone Number Is Requried";
        }
        else if (number.length < 10) {
            errors.number = "Phone Number Is Requried "
        }
        if (!email) {
            errors.email = "Email Is Requried";
        }
        if (email) {
            if (email.match(mailformat)) {

            }
            else {
                errors.email = `Email Must Be In " Text + @ + Text + . + Text " Format`
            }
        }

        if (!massage) {
            errors.massage = "Massage Is Requried";
        }
        return errors;

    }

    return (
        <>

            <section>
                <div className='container-fluid HomeContentMain p-0 d-sm-none d-none d-md-block AbouTMainImg' style={{ backgroundImage: `url(${require("../Img/About.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'top', }}>
                    <Navbar />
                    <div className="container mt-5 pt-5">
                        <div className="row d-flex justify-content-center align-items-center text-center g-4 ">
                            <div className='playfair-display'>
                                <h2 className='mb-3' style={{ fontSize: "55px", fontWeight: "800" }}>
                                    Contact Us
                                </h2>
                                <p className='mb-3 laila-semibold' style={{ fontSize: "18px", fontWeight: "400" }}>
                                    Sign up at Bridely for your upcoming occasion.
                                </p>
                                <p className='mb-3 ' style={{ fontSize: "18px", fontWeight: "400" }}>
                                    Home &nbsp; - &nbsp;
                                    <NavLink className='text-decoration-none' style={{ color: "#F4A492" }}>
                                        <span>
                                            Contact Us
                                        </span>
                                    </NavLink>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='container-fluid HomeContentMain p-0  d-md-none d-sm-block AbouTMainImg' style={{ backgroundImage: `url(${require("../Img/About.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'top', }} >
                    <Navbar />
                    <div className="container pt-5 pb-5 p-3">
                        <div className="row d-flex justify-content-center align-items-center text-center g-4 ">
                            <div className='playfair-display'>
                                <h2 className='mb-3' style={{ fontSize: "40px", fontWeight: "800" }}>
                                    Contact Us
                                </h2>
                                <p className='mb-3 laila-semibold' style={{ fontSize: "15px", fontWeight: "400" }}>
                                    Sign up at Bridely for your upcoming occasion.
                                </p>
                                <p className='mb-3 ' style={{ fontSize: "18px", fontWeight: "400" }}>
                                    Home &nbsp; - &nbsp;
                                    <NavLink className='text-decoration-none' style={{ color: "#F4A492" }}>
                                        <span>
                                            Contact Us
                                        </span>
                                    </NavLink>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid d-md-none d-none d-lg-block" >
                    <div className="container p-5">
                        <div className="row g-4 d-flex justify-content-around pt-5 pb-4 align-items-center text-center">
                            <div className="col-lg-6">
                                <div className='FormSection'>
                                    <div className=''>
                                        <div className='mb-4  ' >
                                            <img className='w-100' src={require("../Img/Contact1.png")} alt="icloudsoft_logo" />
                                        </div>
                                    </div>

                                    <form ref={form} onSubmit={loginHandle} className="row g-4 playfair-display mt-3" style={{ borderRadius: "0px 0px 0px 0px" }}>
                                        <div class="col-lg-6 ">
                                            <input type="text" placeholder='   Name' className="form-control text-dark" style={{ borderRadius: "29px", fontSize: "15px", color: "#555555", backgroundColor: "#fff", border: "1px solid #9ACCC9", height: "55px" }} id="inputName" name="name"
                                                onChange={e => setName(e.target.value)} value={name}
                                            />
                                            {errors.name && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.name}</div>}
                                        </div>

                                        <div className='col-lg-6'>
                                            <input type="email" placeholder='  Email' className="form-control " style={{ borderRadius: "29px", fontSize: "15px", color: "#555555", backgroundColor: "#fff", border: "1px solid #9ACCC9", height: "55px" }} id="inputEmail4" name="email"
                                                onChange={e => setEmail(e.target.value)} value={email}
                                            />
                                            {errors.email && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.email}</div>}
                                        </div>

                                        <div className='col-lg-6'>
                                            <input type="number" placeholder='  Phone' className="form-control " style={{ borderRadius: "29px", fontSize: "15px", color: "#555555", backgroundColor: "#fff", border: "1px solid #9ACCC9", height: "55px" }} id="inputEmail4" name="email"
                                                onChange={e => setNumber(e.target.value)} value={number}
                                            />
                                            {errors.number && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.number}</div>}
                                        </div>

                                        <div className='col-lg-6'>
                                            <input type="date" placeholder='' className="form-control " style={{ borderRadius: "29px", fontSize: "15px", color: "#555555", backgroundColor: "#fff", border: "1px solid #9ACCC9", height: "55px" }} id="inputEmail4" name="email"
                                                onChange={e => setDate(e.target.value)} value={date}
                                            />
                                            {errors.date && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.date}</div>}
                                        </div>

                                        <div className="col-lg-12">
                                            <textarea type="text" placeholder='   Message' className="form-control" style={{ borderRadius: "29px", height: "103px", backgroundColor: "#fff", border: "1px solid #9ACCC9", fontSize: "15px", color: "#555555" }} id="inputText3" name="message"
                                                onChange={e => setMassage(e.target.value)} value={massage}
                                            />
                                            {errors.massage && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.massage}</div>}
                                        </div>

                                        <div className="col-lg-12 mt-lg-2">
                                            <div className='d-flex justify-content-center mb-3 mt-4'>
                                                <button type="submit" className="  FirstButton w-100 "  ><b className='' style={{ fontWeight: "400", fontSize: "16px" }}> Order Services </b></button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div className="col-lg-5">
                                <div className='CategorySectionHome'>
                                    <div className=' text-start'>
                                        <div className='mb-4  ' >
                                            <img className='' src={require("../Img/bird1.png")} alt="icloudsoft_logo" />
                                        </div>
                                    </div>
                                </div>
                                <div className='AboutSectionHome text-start'>
                                    <h3 className='' style={{ fontSize: "50px" }}>
                                        Interested in discussing? Get In touch with us.
                                    </h3>
                                    <p>
                                        Register for your upcoming event at Bridely. Join us and reserve your spot today!
                                    </p>
                                    <h5>
                                        Follow us:
                                    </h5>
                                    <div className='SocialIconFooter'>
                                        <NavLink to="https://www.facebook.com/icloudsofttechnology " target="_blank"><i className="fa-brands fa-square-facebook "></i></NavLink>
                                        <NavLink to="https://twitter.com/icloudsoftTech " target="_blank"><i className="fa-brands fa-square-twitter ms-2"></i></NavLink>
                                        <NavLink to="https://www.instagram.com/icloud_soft/" target="_blank"><i className="fa-brands fa-linkedin ms-2" ></i></NavLink>
                                        <NavLink to="https://in.linkedin.com/in/anil-kadam-18b113147 " target="_blank"><i className="fa-brands fa-square-instagram ms-2"></i></NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid d-lg-none d-md-block" >
                    <div className="container-lg p-3">
                        <div className="row g-4 d-flex justify-content-around pt-5 pb-4 align-items-center text-center">
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className='FormSection'>
                                    <div className=''>
                                        <div className='mb-4  ' >
                                            <img className='w-100' src={require("../Img/Contact1.png")} alt="icloudsoft_logo" />
                                        </div>
                                    </div>

                                    <form ref={form} onSubmit={loginHandle} className="row g-4 playfair-display mt-3" style={{ borderRadius: "0px 0px 0px 0px" }}>
                                        <div class="col-lg-6 col-md-6">
                                            <input type="text" placeholder='   Name' className="form-control text-dark" style={{ borderRadius: "29px", fontSize: "15px", color: "#555555", backgroundColor: "#fff", border: "1px solid #9ACCC9", height: "55px" }} id="inputName" name="name"
                                                onChange={e => setName(e.target.value)} value={name}
                                            />
                                            {errors.name && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.name}</div>}
                                        </div>

                                        <div className='col-lg-6 col-md-6'>
                                            <input type="email" placeholder='  Email' className="form-control " style={{ borderRadius: "29px", fontSize: "15px", color: "#555555", backgroundColor: "#fff", border: "1px solid #9ACCC9", height: "55px" }} id="inputEmail4" name="email"
                                                onChange={e => setEmail(e.target.value)} value={email}
                                            />
                                            {errors.email && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.email}</div>}
                                        </div>

                                        <div className='col-lg-6 col-md-6'>
                                            <input type="number" placeholder='  Phone' className="form-control " style={{ borderRadius: "29px", fontSize: "15px", color: "#555555", backgroundColor: "#fff", border: "1px solid #9ACCC9", height: "55px" }} id="inputEmail4" name="email"
                                                onChange={e => setNumber(e.target.value)} value={number}
                                            />
                                            {errors.number && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.number}</div>}
                                        </div>

                                        <div className='col-lg-6 col-md-6 '>
                                            <input type="date" placeholder='' className="form-control " style={{ borderRadius: "29px", fontSize: "15px", color: "#555555", backgroundColor: "#fff", border: "1px solid #9ACCC9", height: "55px" }} id="inputEmail4" name="email"
                                                onChange={e => setDate(e.target.value)} value={date}
                                            />
                                            {errors.date && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.date}</div>}
                                        </div>

                                        <div className="col-lg-12">
                                            <textarea type="text" placeholder='   Message' className="form-control" style={{ borderRadius: "29px", height: "103px", backgroundColor: "#fff", border: "1px solid #9ACCC9", fontSize: "15px", color: "#555555" }} id="inputText3" name="message"
                                                onChange={e => setMassage(e.target.value)} value={massage}
                                            />
                                            {errors.massage && <div className='text-danger text-start ms-2 mt-1 pt-1' style={{ fontSize: "12px" }}>{errors.massage}</div>}
                                        </div>

                                        <div className="col-lg-12 mt-lg-2">
                                            <div className='d-flex justify-content-center mb-3 mt-4'>
                                                <button type="submit" className="  FirstButton "  ><b className='' style={{ fontWeight: "400", fontSize: "16px" }}>Make Reservations</b></button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div className="col-lg-5 col-md-5 col-sm-5">
                                <div className='CategorySectionHome'>
                                    <div className=' '>
                                        <div className='mb-4  ' >
                                            <img className='' src={require("../Img/bird1.png")} alt="icloudsoft_logo" />
                                        </div>
                                    </div>
                                </div>
                                <div className='AboutSectionHome'>
                                    <h3 className='' style={{ fontSize: "30px" }}>
                                        Interested in discussing? Get In touch with us.
                                    </h3>
                                    <p>
                                        Register for your upcoming event at Bridely. Join us and reserve your spot today!
                                    </p>
                                    <h5>
                                        Follow us:
                                    </h5>
                                    <div className='SocialIconFooter'>
                                        <NavLink to="https://www.facebook.com/icloudsofttechnology " target="_blank"><i className="fa-brands fa-square-facebook "></i></NavLink>
                                        <NavLink to="https://twitter.com/icloudsoftTech " target="_blank"><i className="fa-brands fa-square-twitter ms-2"></i></NavLink>
                                        <NavLink to="https://www.instagram.com/icloud_soft/" target="_blank"><i className="fa-brands fa-linkedin ms-2" ></i></NavLink>
                                        <NavLink to="https://in.linkedin.com/in/anil-kadam-18b113147 " target="_blank"><i className="fa-brands fa-square-instagram ms-2"></i></NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid d-md-none d-none d-lg-block" style={{ backgroundColor: "#EDF7F8" }} >
                    <div className="container p-5">
                        <div className='AboutSectionHome text-center mt-5 pt-4 pb-3 mb-4'>
                            <h6 className='mb-2'>
                                Contact with Us
                            </h6>
                            <h3 className='' style={{ fontSize: "50px" }}>
                                Contact Details
                            </h3>
                        </div>

                        <div className='row '>
                            <div className="col-lg-4 p-0">
                                <div className='MainContactSectionFirst'>
                                    <div className='d-flex  ContactBottomSection'>
                                        <div className='ContactBottomSectionFirst'>
                                            <i class="fa-solid fa-fax " style={{ color: "white" }}></i>
                                        </div>
                                        <h4 className='' >
                                            Phone:
                                            <br />
                                            <NavLink className="text-decoration-none " to='https://wa.me/+919860843466?text='>
                                                <h6 className='CommonHoverContact mt-1 pt-2' >
                                                    +(123) 456 789 111
                                                </h6>
                                            </NavLink>
                                            <NavLink className="text-decoration-none " to='https://wa.me/+919860843466?text='>
                                                <h6 className='CommonHoverContact mt-2' >
                                                    +(321) 111 456 789
                                                </h6>
                                            </NavLink>
                                        </h4>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 p-0">
                                <div className='MainContactSectionSecond'>
                                    <div className='d-flex  ContactBottomSection'>
                                        <div className='ContactBottomSectionFirst'>
                                            <i class="fa-solid fa-map-location" style={{ color: "white" }}></i>
                                        </div>
                                        <h4 className=''>
                                            Address:
                                            <br />
                                            <NavLink className="text-decoration-none " to='https://wa.me/+919860843466?text='>
                                                <h6 className='CommonHoverContactAdrres mt-2 pt-1' style={{ lineHeight: "25px" }} >
                                                    121 King Street Melbourne, <br /> 3000, Australia
                                                </h6>
                                            </NavLink>
                                        </h4>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 p-0">
                                <div className='MainContactSectionThird'>
                                    <div className='d-flex  ContactBottomSection'>
                                        <div className='ContactBottomSectionFirst'>
                                            <i class="fa-solid fa-envelope" style={{ color: "white" }}></i>
                                        </div>
                                        <h4 className=''>
                                            Email:
                                            <br />
                                            <NavLink className="text-decoration-none " to='https://wa.me/+919860843466?text='>
                                                <h6 className='CommonHoverContact mt-2 pt-1' >
                                                    support@bridely.com
                                                </h6>
                                            </NavLink>
                                            <NavLink className="text-decoration-none " to='https://wa.me/+919860843466?text='>
                                                <h6 className='CommonHoverContact mt-2' >
                                                    events@bridely.com
                                                </h6>
                                            </NavLink>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid d-lg-none d-md-block" style={{ backgroundColor: "#EDF7F8" }} >
                    <div className="container-lg p-3">
                        <div className='AboutSectionHome text-center mt-3 pt-4 pb-3 mb-3'>
                            <h6 className='mb-2'>
                                Contact with Us
                            </h6>
                            <h3 className='' style={{ fontSize: "40px" }}>
                                Contact Details
                            </h3>
                        </div>

                        <div className='row text-center'>
                            <div className="col-lg-4 col-md-4 col-sm-6 p-0">
                                <div className='MainContactSectionFirst'>
                                    <div className='d-flex  ContactBottomSection'>
                                        <div className='ContactBottomSectionFirst'>
                                            <i class="fa-solid fa-fax " style={{ color: "white" }}></i>
                                        </div>
                                        <h4 className='' >
                                            Phone:
                                            <br />
                                            <NavLink className="text-decoration-none " to='https://wa.me/+919860843466?text='>
                                                <h6 className='CommonHoverContact mt-1 pt-2' >
                                                    +(123) 456 789 111
                                                </h6>
                                            </NavLink>
                                            <NavLink className="text-decoration-none " to='https://wa.me/+919860843466?text='>
                                                <h6 className='CommonHoverContact mt-2' >
                                                    +(321) 111 456 789
                                                </h6>
                                            </NavLink>
                                        </h4>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-4 col-sm-6 p-0">
                                <div className='MainContactSectionSecond'>
                                    <div className='d-flex  ContactBottomSection'>
                                        <div className='ContactBottomSectionFirst'>
                                            <i class="fa-solid fa-map-location" style={{ color: "white" }}></i>
                                        </div>
                                        <h4 className=''>
                                            Address:
                                            <br />
                                            <NavLink className="text-decoration-none " to='https://wa.me/+919860843466?text='>
                                                <h6 className='CommonHoverContactAdrres mt-2 pt-1' style={{ lineHeight: "25px" }} >
                                                    121 King Street Melbourne, <br /> 3000, Australia
                                                </h6>
                                            </NavLink>
                                        </h4>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-4 col-sm-6 p-0">
                                <div className='MainContactSectionThird'>
                                    <div className='d-flex  ContactBottomSection'>
                                        <div className='ContactBottomSectionFirst'>
                                            <i class="fa-solid fa-envelope" style={{ color: "white" }}></i>
                                        </div>
                                        <h4 className=''>
                                            Email:
                                            <br />
                                            <NavLink className="text-decoration-none " to='https://wa.me/+919860843466?text='>
                                                <h6 className='CommonHoverContact mt-2 pt-1' >
                                                    support@bridely.com
                                                </h6>
                                            </NavLink>
                                            <NavLink className="text-decoration-none " to='https://wa.me/+919860843466?text='>
                                                <h6 className='CommonHoverContact mt-2' >
                                                    events@bridely.com
                                                </h6>
                                            </NavLink>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container p-5 mt-5 d-md-none d-none d-lg-block">
                    <div className='' style={{ lineHeight: "0px", border: "5px solid #fff", boxShadow: "0px 0px 50px 0px rgba(0, 0, 0, 0.1)", borderRadius: "50px 0px 50px 0px" }}>
                        <iframe className='w-100 m-0' title="gmap" style={{ height: "350px", borderRadius: "50px 0px 50px 0px" }} src="https://maps.google.com/maps?q=Envato%2C%20121%20King%20St%2C%20Melbourne%20VIC%203000%2C%20Australia&t=m&z=14&output=embed&iwloc=near">
                        </iframe>
                    </div>
                </div>

                <div className="container-lg p-3 mt-3 d-lg-none d-md-block">
                    <div className='' style={{ lineHeight: "0px", border: "5px solid #fff", boxShadow: "0px 0px 50px 0px rgba(0, 0, 0, 0.1)", borderRadius: "50px 0px 50px 0px" }}>
                        <iframe className='w-100 m-0' title="gmap" style={{ height: "300px", borderRadius: "50px 0px 50px 0px" }} src="https://maps.google.com/maps?q=Envato%2C%20121%20King%20St%2C%20Melbourne%20VIC%203000%2C%20Australia&t=m&z=14&output=embed&iwloc=near">
                        </iframe>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Contact