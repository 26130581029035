import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Home from './Home'
import Footer from './Footer'
import Error from './Error'
import About from './About'
import TopBottomButton from './TopBottomButton'
import Services from './Services'
import Pricing from './Pricing'
import Team from './Team'
import Testimonial from './Testimonial'
import Silder from './Silder'
import Gallery from './Gallery'
import Contact from './Contact'

const Routing = () => {
    return (
        <>

            <React.Fragment>
                <main>
                    <Routes>
                        <Route exact to path='/' element={<Home />} />
                        <Route exact to path='/about' element={<About />} />
                        <Route exact to path='/services' element={<Services />} />
                        <Route exact to path='/pricing' element={<Pricing />} />
                        <Route exact to path='/team' element={<Team />} />
                        <Route exact to path='/testimonial' element={<Testimonial />} />
                        <Route exact to path='/gallery' element={<Gallery />} />
                        <Route exact to path='/contact' element={<Contact />} />
                        <Route exact to path='/silder' element={<Silder />} />
                        <Route exact to path='*' element={<Error />} />
                    </Routes>
                </main>
                <TopBottomButton />
                <footer>
                    <Footer />
                </footer>
            </React.Fragment>

        </>
    )
}

export default Routing