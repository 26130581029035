import React from 'react'
import "./Footer.css"
import { NavLink } from 'react-router-dom'

const Footer = () => {
    return (
        <>

            <section>
                <div className="container-fluid d-md-none d-none d-lg-block" >
                    <div className="container-lg p-5">
                        <div className="row d-flex justify-content-center align-items-center MainText">
                            <div className="col-xl-5 col-lg-5">
                                <div className='RecentPostTop'>
                                    <h2>
                                        Let's Start Planning!
                                    </h2>
                                </div>
                            </div>

                            <div className="col-xl-5 col-lg-5">
                                <div className="row d-flex">
                                    <div className=' d-flex' style={{ gap: "20px" }}>
                                        <div className='FooterSectionFirst'>
                                            <button>
                                                Make Reservations
                                            </button>
                                        </div>

                                        <div className='FooterSectionSecond'>
                                            <button>
                                                Learn More
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid d-lg-none d-md-block" >
                    <div className="container-lg p-3">
                        <div className="row d-flex justify-content-center align-items-center text-center mt-5 mb-5 MainText">
                            <div className="col-xl-5 col-lg-5">
                                <div className='RecentPostTop'>
                                    <h2 className='' style={{ fontSize: "30px" }}>
                                        Let's Start Planning!
                                    </h2>
                                </div>
                            </div>

                            <div className="col-xl-6 col-lg-6">
                                <div className="row d-flex justify-content-center align-items-center mt-2">
                                    <div className=' d-flex justify-content-center align-items-center' style={{ gap: "8px" }}>
                                        <div className='FooterSectionFirst'>
                                            <button>
                                                Make Reservations
                                            </button>
                                        </div>

                                        <div className='FooterSectionSecond'>
                                            <button>
                                                Learn More
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-lg-3 pb-3 d-sm-none d-none d-md-block" >
                    <div className="container-lg laila-semibold pt-lg-5">
                        <div className="row p-lg-3 g-4 d-flex justify-content-around align-items-center ">
                            <div className="col-lg-5 col-md-12 ">
                                <div className=' HeartsUp'>
                                    <div className='mb-4  HeartsDown' >
                                        <img className='w-100' src={require("../Img/hearts.png")} alt="icloudsoft_logo" />
                                    </div>
                                </div>
                                <div className='MainFooter ' style={{ backgroundImage: `url(${require("../Img/MainfooterBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", width: "400px", height: '400px', backgroundPosition: "center" }}>
                                    <div className='p-5 text-center'>
                                        <div className='mb-4 mt-5 text-center'>
                                            <img className='' src={require("../Img/logo.png")} alt="icloudsoft_logo" />
                                        </div>
                                        <p>
                                            From 'Yes' to 'Yes, We Did It, Create Your Fairytale Ending with Bridely: The Premier Wedding Planning Platform
                                        </p>
                                        <div className='SocialIconFooter'>
                                            <NavLink to="https://www.facebook.com/icloudsofttechnology " target="_blank"><i className="fa-brands fa-facebook-f SocialIconFooterFirst"></i></NavLink>
                                            <NavLink to="https://twitter.com/icloudsoftTech " target="_blank"><i className="fa-brands fa-twitter SocialIconFooterSecond"></i></NavLink>
                                            <NavLink to="https://www.instagram.com/icloud_soft/" target="_blank"><i className="fa-brands fa-linkedin-in SocialIconFooterThird" ></i></NavLink>
                                            <NavLink to="https://in.linkedin.com/in/anil-kadam-18b113147 " target="_blank"><i className="fa-brands fa-instagram SocialIconFooterFour"></i></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-4 ">
                                <div className='' >
                                    <h6 className='SolutionDropDownFontFamily2 text-decoration-none mt-3' style={{ fontSize: "18px", fontWeight: "600", paddingBottom: "8px", color: "#00000" }} >COMPANY</h6>
                                </div>
                                <div className='mb-1' style={{ borderTop: "2px solid #659833", width: "50px" }}>

                                </div>
                                <div className='pt-1'>
                                    <div className='mt-3 fontFamilyHeader1' style={{ color: "#ccdbeb", fontSize: "16px", lineHeight: "19px", fontWeight: "400", cursor: "pointer" }}>
                                        <NavLink to='/' className='text-decoration-none FooterHover' > <i class="fa-solid fa-angle-right me-2 " ></i> <p className='ps-lg-1'>Home</p></NavLink>
                                        <NavLink to='/about' className='text-decoration-none FooterHover' ><i class="fa-solid fa-angle-right me-2"></i> <p className='ps-lg-1'>About</p></NavLink>
                                        <NavLink to='/services' className='text-decoration-none FooterHover' ><i class="fa-solid fa-angle-right me-2"></i> <p className='ps-lg-1'>Services</p></NavLink>
                                        <NavLink to='/contact' className='text-decoration-none FooterHover' > <i class="fa-solid fa-angle-right me-2"></i><p className='ps-lg-1'>Contact</p></NavLink>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-4 ">
                                <div className='' >
                                    <h6 className='SolutionDropDownFontFamily2 text-decoration-none mt-3' style={{ fontSize: "18px", fontWeight: "600", paddingBottom: "8px", color: "#fff" }} >Follow Us Now</h6>
                                </div>

                                <div className='pt-1'>
                                    <div className='mt-3 fontFamilyHeader1' style={{ color: "#ccdbeb", fontSize: "16px", lineHeight: "19px", fontWeight: "400", cursor: "pointer" }}>
                                        <NavLink to='/pricing' className='text-decoration-none FooterHover' > <i class="fa-solid fa-angle-right me-2 " ></i> <p className='ps-lg-1'>Pricing</p></NavLink>
                                        <NavLink to='/team' className='text-decoration-none FooterHover' ><i class="fa-solid fa-angle-right me-2"></i> <p className='ps-lg-1'>Team</p></NavLink>
                                        <NavLink to='/testimonial' className='text-decoration-none FooterHover' > <i class="fa-solid fa-angle-right me-2"></i><p className='ps-lg-1'>Testimonial</p></NavLink>
                                        <NavLink to='/gallery' className='text-decoration-none FooterHover'> <i class="fa-solid fa-angle-right me-2"></i><p className='ps-lg-1'>Gallery</p></NavLink>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 ">
                                <div className='' >
                                    <h6 className='SolutionDropDownFontFamily2 text-decoration-none mt-1' style={{ fontSize: "18px", fontWeight: "600", paddingBottom: "8px", color: "#00000" }} >CONTACT US</h6>
                                </div>
                                <div className='mb-1' style={{ borderTop: "2px solid #659833", width: "50px" }}>

                                </div>
                                <div className='pt-1'>
                                    <div className='mt-3 fontFamilyHeader1'>
                                        <NavLink to='about' className='text-decoration-none FooterHoverFirst' ><i class="fa-solid fa-paper-plane me-2"></i> <p className='ps-lg-2' >help@xyz.com</p></NavLink>
                                        <NavLink to='about' className='text-decoration-none FooterHoverFirst' ><i class="fa-solid fa-fax me-2"></i> <p className='ps-lg-2' >+91 123 4567 890</p></NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid pt-lg-3 pb-3 d-md-none d-sm-block" >
                    <div className="container-lg laila-semibold">
                        <div className="row g-4 d-flex justify-content-around align-items-center ">
                            <div className="col-lg-5 col-md-6 ">
                                <div className=' HeartsUpFooter '>
                                    <div className='mb-4  HeartsDownFooter' >
                                        <img className='' src={require("../Img/hearts.png")} alt="icloudsoft_logo" />
                                    </div>
                                </div>

                                <div className=' mt-5' style={{ backgroundImage: `url(${require("../Img/MainfooterBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "contain", width: "320px", height: '320px', backgroundPosition: "center" }}>
                                    <div className='p-4 text-center'>
                                        <div className='mb-4 mt-2 text-center'>
                                            <img className='w-50' src={require("../Img/logo.png")} alt="icloudsoft_logo" />
                                        </div>
                                        <p className='text-center' style={{ fontSize: "15px" }}>
                                            From 'Yes' to 'Yes, We Did It, Create Your Fairytale Ending with Bridely: The Premier Wedding Planning Platform
                                        </p>
                                        <div className='SocialIconFooter'>
                                            <NavLink to=" " target="_blank"><i className="fa-brands fa-facebook-f SocialIconFooterFirst"></i></NavLink>
                                            <NavLink to="" target="_blank"><i className="fa-brands fa-twitter SocialIconFooterSecond"></i></NavLink>
                                            <NavLink to="" target="_blank"><i className="fa-brands fa-linkedin-in SocialIconFooterThird" ></i></NavLink>
                                            <NavLink to="" target="_blank"><i className="fa-brands fa-instagram SocialIconFooterFour"></i></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-6 col-6">
                                <div className='' >
                                    <h6 className='SolutionDropDownFontFamily2 text-decoration-none mt-3' style={{ fontSize: "18px", fontWeight: "600", paddingBottom: "8px", color: "#00000" }} >COMPANY</h6>
                                </div>
                                <div className='mb-1' style={{ borderTop: "2px solid #659833", width: "50px" }}>

                                </div>
                                <div className='pt-1'>
                                    <div className='mt-3 fontFamilyHeader1' style={{ color: "#ccdbeb", fontSize: "16px", lineHeight: "19px", fontWeight: "400", cursor: "pointer" }}>
                                        <NavLink to='main' className='text-decoration-none FooterHover' > <i class="fa-solid fa-angle-right me-2 " ></i> <p className='ps-lg-1'>Home</p></NavLink>
                                        <NavLink to='services' className='text-decoration-none FooterHover' ><i class="fa-solid fa-angle-right me-2"></i> <p className='ps-lg-1'>About</p></NavLink>
                                        <NavLink to='about' className='text-decoration-none FooterHover' ><i class="fa-solid fa-angle-right me-2"></i> <p className='ps-lg-1'>Services</p></NavLink>
                                        <NavLink to='product' className='text-decoration-none FooterHover' > <i class="fa-solid fa-angle-right me-2"></i><p className='ps-lg-1'>Contact</p></NavLink>
                                    </div>
                                </div>

                                <div className='mt-3 fontFamilyHeader1'>
                                    <NavLink to='/about' className='text-decoration-none FooterHoverFirst mb-2' ><i class="fa-solid fa-paper-plane me-2"></i> </NavLink>
                                    <NavLink to='/about' className='text-decoration-none FooterHoverFirst' > <p className='ps-lg-2' >help@xyz.com</p></NavLink>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-6 col-6">
                                <div className='' >
                                    <h6 className='SolutionDropDownFontFamily2 text-decoration-none mt-3' style={{ fontSize: "18px", fontWeight: "600", paddingBottom: "8px", color: "#fff" }} >Follow Us Now</h6>
                                </div>

                                <div className='pt-1'>
                                    <div className='mt-3 fontFamilyHeader1' style={{ color: "#ccdbeb", fontSize: "16px", lineHeight: "19px", fontWeight: "400", cursor: "pointer" }}>
                                        <NavLink to='main' className='text-decoration-none FooterHover' > <i class="fa-solid fa-angle-right me-2 " ></i> <p className='ps-lg-1'>Support</p></NavLink>
                                        <NavLink to='services' className='text-decoration-none FooterHover' ><i class="fa-solid fa-angle-right me-2"></i> <p className='ps-lg-1'>Career</p></NavLink>
                                        <NavLink to='product' className='text-decoration-none FooterHover' > <i class="fa-solid fa-angle-right me-2"></i><p className='ps-lg-1'>FAQ's</p></NavLink>
                                        <NavLink to='contact' className='text-decoration-none FooterHover'> <i class="fa-solid fa-angle-right me-2"></i><p className='ps-lg-1'>Blog</p></NavLink>
                                    </div>
                                </div>

                                <div className='mt-3 fontFamilyHeader1'>
                                    <NavLink to='/about' className='text-decoration-none FooterHoverFirst mb-2' ><i class="fa-solid fa-fax me-2"></i> </NavLink>
                                    <NavLink to='/about' className='text-decoration-none FooterHoverFirst' > <p className='ps-lg-2' >+91 123 4567 890</p></NavLink>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </section >

            <section>
                <div className="container-fluid d-sm-none d-none d-lg-block" style={{ backgroundColor: '#edf7f8' }}>
                    <div className="container p-3">
                        <div className="d-flex justify-content-center align-items-center" >
                            <div className='text-center pt-1' style={{ fontSize: "14px", fontWeight: "600", paddingBottom: "4px", color: "#6c6c6c" }} >
                                Copyright © Xyz 2024. All Rights Reserved 222
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid d-lg-none d-sm-block" style={{ backgroundColor: '#edf7f8' }}>
                    <div className="container p-3">
                        <div className="d-flex justify-content-center align-items-center" >
                            <div className='text-center pt-1' style={{ fontSize: "14px", fontWeight: "600", paddingBottom: "4px", color: "#6c6c6c" }} >
                                Copyright © Xyz 2024. All Rights Reserved
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Footer