import React from 'react'
import "./Pricing.css"
import Navbar from './Navbar'
import { NavLink } from 'react-router-dom'

const Pricing = () => {
    return (
        <>

            <section>
                <div className='container-fluid HomeContentMain p-0 d-sm-none d-none d-md-block AbouTMainImg' style={{ backgroundImage: `url(${require("../Img/About.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'top', }}>
                    <Navbar />
                    <div className="container mt-5 pt-5">
                        <div className="row d-flex justify-content-center align-items-center text-center g-4 ">
                            <div className='playfair-display'>
                                <h2 className='mb-3' style={{ fontSize: "55px", fontWeight: "800" }}>
                                    Pricing
                                </h2>
                                <p className='mb-3 laila-semibold' style={{ fontSize: "18px", fontWeight: "400" }}>
                                    Plan your event with us at best and affordable prices
                                </p>
                                <p className='mb-3 ' style={{ fontSize: "18px", fontWeight: "400" }}>
                                    Home &nbsp; - &nbsp;
                                    <NavLink className='text-decoration-none' style={{ color: "#F4A492" }}>
                                        <span>
                                            Pricing
                                        </span>
                                    </NavLink>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='container-fluid HomeContentMain p-0  d-md-none d-sm-block AbouTMainImg' style={{ backgroundImage: `url(${require("../Img/About.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'top', }} >
                    <Navbar />
                    <div className="container pt-5 pb-5 p-3">
                        <div className="row d-flex justify-content-center align-items-center text-center g-4 ">
                            <div className='playfair-display'>
                                <h2 className='mb-3' style={{ fontSize: "40px", fontWeight: "800" }}>
                                    Pricing
                                </h2>
                                <p className='mb-3 laila-semibold' style={{ fontSize: "15px", fontWeight: "400" }}>
                                    Plan your event with us at best and affordable prices
                                </p>
                                <p className='mb-3 ' style={{ fontSize: "18px", fontWeight: "400" }}>
                                    Home &nbsp; - &nbsp;
                                    <NavLink className='text-decoration-none' style={{ color: "#F4A492" }}>
                                        <span>
                                            Pricing
                                        </span>
                                    </NavLink>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid d-md-none d-none d-lg-block" >
                    <div className="container p-5">
                        <div className="row g-4 p-1 text-center">
                            <div className="col-xl-4 col-md-6">
                                <div className='MainPricing'>
                                    <img className='' src={require("../Img/Pricing1.png")} alt="icloudsoft_logo" />
                                    <h2>
                                        Basic
                                    </h2>
                                    <p>
                                        Our basic plan includes :-
                                    </p>
                                    <h3>
                                        $ 250
                                    </h3>
                                    <h6>
                                        Stylish <b> makeup </b> for bride.
                                    </h6>

                                    <h6>
                                        Top label  <b> arrangement </b>
                                    </h6>

                                    <h6>
                                        Amazing <b> meal & breakfast </b>
                                    </h6>

                                    <h6>
                                        Manicure & Pedicure
                                    </h6>

                                    <h6>
                                        Full <b> body </b> Polish
                                    </h6>

                                    <div className='d-flex justify-content-center mb-3 '>
                                        <NavLink className='text-decoration-none' to='http://wa.me/9527468898'>
                                            <button className='text-center PricingTableMainSecondButton'>
                                                Order Now
                                            </button>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-md-6">
                                <div className='MainPricing'>
                                    <img className='' src={require("../Img/Pricing2.png")} alt="icloudsoft_logo" />
                                    <h2>
                                        Standard
                                    </h2>
                                    <p>
                                    Our standard plan includes :-
                                    </p>
                                    <h3>
                                        $ 375
                                    </h3>
                                    <h6>
                                        Stylish <b> makeup </b> for bride.
                                    </h6>

                                    <h6>
                                        Top label  <b> arrangement </b>
                                    </h6>

                                    <h6>
                                        Amazing <b> meal & breakfast </b>
                                    </h6>

                                    <h6>
                                        Manicure & Pedicure
                                    </h6>

                                    <h6>
                                        Full <b> body </b> Polish
                                    </h6>

                                    <div className='d-flex justify-content-center mb-3 '>
                                        <NavLink className='text-decoration-none' to='http://wa.me/9527468898'>
                                            <button className='text-center PricingTableMainSecondButton'>
                                                Order Now
                                            </button>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-md-6">
                                <div className='MainPricing'>
                                    <img className='' src={require("../Img/Pricing3.png")} alt="icloudsoft_logo" />
                                    <h2>
                                        Luxury
                                    </h2>
                                    <p>
                                    Our luxury plan includes :-
                                    </p>
                                    <h3>
                                        $ 749
                                    </h3>
                                    <h6>
                                        Stylish <b> makeup </b> for bride.
                                    </h6>

                                    <h6>
                                        Top label  <b> arrangement </b>
                                    </h6>

                                    <h6>
                                        Amazing <b> meal & breakfast </b>
                                    </h6>

                                    <h6>
                                        Manicure & Pedicure
                                    </h6>

                                    <h6>
                                        Full <b> body </b> Polish
                                    </h6>

                                    <div className='d-flex justify-content-center mb-3 '>
                                        <NavLink className='text-decoration-none' to='http://wa.me/9527468898'>
                                            <button className='text-center PricingTableMainSecondButton'>
                                                Order Now
                                            </button>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid d-lg-none d-md-block" >
                    <div className="container p-2 mt-4">
                        <div className="row g-4  text-center">
                            <div className="col-xl-4 col-md-6">
                                <div className='MainPricing'>
                                    <img className='' src={require("../Img/Pricing1.png")} alt="icloudsoft_logo" />
                                    <h2>
                                        Basic
                                    </h2>
                                    <p>
                                    Our basic plan includes :-
                                    </p>
                                    <h3>
                                        $ 250
                                    </h3>
                                    <h6>
                                        Stylish <b> makeup </b> for bride.
                                    </h6>

                                    <h6>
                                        Top label  <b> arrangement </b>
                                    </h6>

                                    <h6>
                                        Amazing <b> meal & breakfast </b>
                                    </h6>

                                    <h6>
                                        Manicure & Pedicure
                                    </h6>

                                    <h6>
                                        Full <b> body </b> Polish
                                    </h6>

                                    <div className='d-flex justify-content-center mb-3 '>
                                        <NavLink className='text-decoration-none' to='http://wa.me/9527468898'>
                                            <button className='text-center PricingTableMainSecondButton'>
                                                Order Now
                                            </button>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-md-6">
                                <div className='MainPricing'>
                                    <img className='' src={require("../Img/Pricing2.png")} alt="icloudsoft_logo" />
                                    <h2>
                                        Standard
                                    </h2>
                                    <p>
                                    Our standard plan includes :-
                                    </p>
                                    <h3>
                                        $ 375
                                    </h3>
                                    <h6>
                                        Stylish <b> makeup </b> for bride.
                                    </h6>

                                    <h6>
                                        Top label  <b> arrangement </b>
                                    </h6>

                                    <h6>
                                        Amazing <b> meal & breakfast </b>
                                    </h6>

                                    <h6>
                                        Manicure & Pedicure
                                    </h6>

                                    <h6>
                                        Full <b> body </b> Polish
                                    </h6>

                                    <div className='d-flex justify-content-center mb-3 '>
                                        <NavLink className='text-decoration-none' to='http://wa.me/9527468898'>
                                            <button className='text-center PricingTableMainSecondButton'>
                                                Order Now
                                            </button>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-md-6">
                                <div className='MainPricing'>
                                    <img className='' src={require("../Img/Pricing3.png")} alt="icloudsoft_logo" />
                                    <h2>
                                        Luxury
                                    </h2>
                                    <p>
                                    Our luxury plan includes :-
                                    </p>
                                    <h3>
                                        $ 749
                                    </h3>
                                    <h6>
                                        Stylish <b> makeup </b> for bride.
                                    </h6>

                                    <h6>
                                        Top label  <b> arrangement </b>
                                    </h6>

                                    <h6>
                                        Amazing <b> meal & breakfast </b>
                                    </h6>

                                    <h6>
                                        Manicure & Pedicure
                                    </h6>

                                    <h6>
                                        Full <b> body </b> Polish
                                    </h6>

                                    <div className='d-flex justify-content-center mb-3 '>
                                        <NavLink className='text-decoration-none' to='http://wa.me/9527468898'>
                                            <button className='text-center PricingTableMainSecondButton'>
                                                Order Now
                                            </button>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid d-md-none d-none d-lg-block" style={{ backgroundImage: `url(${require("../Img/CategoryBg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "auto", backgroundPosition: 'right', backgroundColor: "#EDF7F8" }} >
                    <div className="container p-5 pb-5 mb-5">
                        <div className="row g-4 d-flex justify-content-center pt-5 pb-5 mb-5 mt-4 align-items-center text-center">
                            <div className="col-lg-5 col-md-4">

                                <div className='position-relative'>
                                    <div className='story-section position-absolute' style={{ top: "-130px", left: "3%", width: "600px" }}>
                                        <div className='birdUpSecond'>
                                            <div className='birdDownSecond' >
                                                <img className='' src={require("../Img/bird.png")} alt="icloudsoft_logo" />
                                            </div>
                                        </div>
                                        <div className='mb-4'>
                                            <img className='' src={require("../Img/quote_icon.png")} alt="icloudsoft_logo" />
                                        </div>
                                        <h3>
                                            Marry Jain & Jamie Foster
                                        </h3>
                                        <p>
                                            Bridely truly exceeded all of my expectations as a wedding planner.They took the time to understand my vision and preferences, and flawlessly executed every detail. I was able to relax and enjoy every moment of my wedding day
                                        </p>
                                        <button>
                                            See the Story
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-8">
                                <div className='story-section-img '>
                                    <img className='w-100 ' src={require("../Img/story-section-img.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid d-lg-none d-md-block" style={{ backgroundImage: `url(${require("../Img/CategoryBg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "auto", backgroundPosition: 'center', backgroundColor: "#EDF7F8" }} >
                    <div className="container p-3">
                        <div className="row g-2 d-flex justify-content-center mb-3 mt-3 align-items-center text-center">
                            <div className="col-lg-6 col-md-6">
                                <div className='story-section-img '>
                                    <img className='w-100 ' src={require("../Img/story-section-img.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <div className=''>
                                    <div className='story-section p-3 ' >
                                        <div className='mb-4'>
                                            <img className='' src={require("../Img/quote_icon.png")} alt="icloudsoft_logo" />
                                        </div>
                                        <h3 className='' style={{ fontSize: "25px" }}>
                                            Marry Jain & Jamie Foster
                                        </h3>
                                        <p className='' style={{ fontSize: "14px" }}>
                                            Bridely truly exceeded all of my expectations as a wedding planner.They took the time to understand my vision and preferences, and flawlessly executed every detail. I was able to relax and enjoy every moment of my wedding day
                                        </p>
                                        <button>
                                            See the Story
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid d-sm-none d-none d-md-block" style={{ backgroundImage: `url(${require("../Img/InstagramBg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "contain", height: "1300px", backgroundPosition: 'bottom' }}>
                    <div className="container p-5 text-center" >
                        <div className='d-flex justify-content-center text-center' >
                            <div className='mb-4 mt-4'>
                                <img className='' src={require("../Img/instagram_icon.png")} alt="icloudsoft_logo" />
                            </div>
                            <div className='BirdUpThird '>
                                <div className='BirdDownThird' >
                                    <img className='' src={require("../Img/bird.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>
                        </div>
                        <h3 className='fs-1 playfair-display'>
                            Instagram Feed
                        </h3>
                        <div className="row g-4 d-flex justify-content-around pt-5  align-items-center text-center">
                            <div className="col-xl-3 col-lg-6 col-md-6">
                                <div className='CommonImg'>
                                    <img className='' src={require("../Img/FooterTop1.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-6 col-md-6">
                                <div className='CommonImg mt-5'>
                                    <img className='' src={require("../Img/FooterTop2.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-6 col-md-6">
                                <div className='CommonImg'>
                                    <img className='' src={require("../Img/FooterTop3.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-6 col-md-6">
                                <div className='CommonImg mt-5'>
                                    <img className='' src={require("../Img/FooterTop4.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid d-md-none d-sm-block CoupleImg" style={{ backgroundImage: `url(${require("../Img/InstagramBg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "contain", backgroundPosition: 'bottom' }}>
                    <div className="container p-3 text-center" >
                        <div className='d-flex justify-content-center text-center' >
                            <div className='mb-4 mt-4'>
                                <img className='' src={require("../Img/instagram_icon.png")} alt="icloudsoft_logo" />
                            </div>
                            <div className='BirdUpThird '>
                                <div className='BirdDownThird' >
                                    <img className='' src={require("../Img/bird.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>
                        </div>
                        <h3 className='fs-1 playfair-display'>
                            Instagram Feed
                        </h3>
                        <div className="row g-4 d-flex justify-content-around pt-4  align-items-center text-center">
                            <div className="col-lg-3 col-6">
                                <div className='CommonImg'>
                                    <img className='w-100' src={require("../Img/FooterTop1.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>

                            <div className="col-lg-3 col-6">
                                <div className='CommonImg '>
                                    <img className='w-100' src={require("../Img/FooterTop2.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>

                            <div className="col-lg-3 col-6">
                                <div className='CommonImg'>
                                    <img className='w-100' src={require("../Img/FooterTop3.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>

                            <div className="col-lg-3 col-6">
                                <div className='CommonImg'>
                                    <img className='w-100' src={require("../Img/FooterTop4.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Pricing