import React from 'react'
import "./Gallery.css"
import { NavLink } from 'react-router-dom'
import Navbar from './Navbar'

const Gallery = () => {
    return (
        <>

            <section>
                <div className='container-fluid HomeContentMain p-0 d-sm-none d-none d-md-block AbouTMainImg' style={{ backgroundImage: `url(${require("../Img/About.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'top', }}>
                    <Navbar />
                    <div className="container mt-5 pt-5">
                        <div className="row d-flex justify-content-center align-items-center text-center g-4 ">
                            <div className='playfair-display'>
                                <h2 className='mb-3' style={{ fontSize: "55px", fontWeight: "800" }}>
                                    Gallery
                                </h2>
                                <p className='mb-3 laila-semibold' style={{ fontSize: "18px", fontWeight: "400" }}>
                                    Witness what we have captured.
                                </p>
                                <p className='mb-3 ' style={{ fontSize: "18px", fontWeight: "400" }}>
                                    Home &nbsp; - &nbsp;
                                    <NavLink className='text-decoration-none' style={{ color: "#F4A492" }}>
                                        <span>
                                            Gallery
                                        </span>
                                    </NavLink>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='container-fluid HomeContentMain p-0  d-md-none d-sm-block AbouTMainImg' style={{ backgroundImage: `url(${require("../Img/About.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'top', }} >
                    <Navbar />
                    <div className="container pt-5 pb-5 p-3">
                        <div className="row d-flex justify-content-center align-items-center text-center g-4 ">
                            <div className='playfair-display'>
                                <h2 className='mb-3' style={{ fontSize: "40px", fontWeight: "800" }}>
                                    Gallery
                                </h2>
                                <p className='mb-3 laila-semibold' style={{ fontSize: "15px", fontWeight: "400" }}>
                                    Witness what we have captured.
                                </p>
                                <p className='mb-3 ' style={{ fontSize: "18px", fontWeight: "400" }}>
                                    Home &nbsp; - &nbsp;
                                    <NavLink className='text-decoration-none' style={{ color: "#F4A492" }}>
                                        <span>
                                            Gallery
                                        </span>
                                    </NavLink>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid d-md-none d-none d-lg-block" >
                    <div className="container p-5">
                        <div className='AboutSectionHome text-center mt-5 pt-4 pb-3 mb-4'>
                            <h6 className='mb-2'>
                                Unforgettable Events
                            </h6>
                            <h3 className='' style={{ fontSize: "50px" }}>
                                Capturing Love and Celebration <br /> in Visual Showcase
                            </h3>
                        </div>

                        <div className='row g-4'>
                            <div className="col-lg-4">
                                <img className='w-100' style={{ borderRadius: "30px" }} src={require("../Img/Gallery1.png")} alt="icloudsoft_logo" />
                            </div>

                            <div className="col-lg-4">
                                <img className='w-100' style={{ borderRadius: "30px" }} src={require("../Img/Gallery5.png")} alt="icloudsoft_logo" />
                            </div>

                            <div className="col-lg-4">
                                <img className='w-100' style={{ borderRadius: "30px" }} src={require("../Img/Gallery4.png")} alt="icloudsoft_logo" />
                            </div>

                            <div className="col-lg-4">
                                <img className='w-100' style={{ borderRadius: "30px" }} src={require("../Img/Gallery3.png")} alt="icloudsoft_logo" />
                            </div>

                            <div className="col-lg-4">
                                <img className='w-100' style={{ borderRadius: "30px" }} src={require("../Img/Gallery2.png")} alt="icloudsoft_logo" />
                            </div>

                            <div className="col-lg-4">
                                <img className='w-100' style={{ borderRadius: "30px" }} src={require("../Img/Gallery6.png")} alt="icloudsoft_logo" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid d-lg-none d-md-block" >
                    <div className="container-lg p-3">
                        <div className='AboutSectionHome text-center mt-4 pb-2 mb-2'>
                            <h6 className='mb-2'>
                                Unforgettable Events
                            </h6>
                            <h3 className='mt-3' style={{ fontSize: "20px", lineHeight: "27px" }}>
                                Capturing Love and Celebration in Visual Showcase
                            </h3>
                        </div>
                        <div className='row g-4'>
                            <div className="col-lg-4 col-md-4 col-sm-6">
                                <img className='w-100' style={{ borderRadius: "30px" }} src={require("../Img/Gallery1.png")} alt="icloudsoft_logo" />
                            </div>

                            <div className="col-lg-4 col-md-4 col-sm-6">
                                <img className='w-100' style={{ borderRadius: "30px" }} src={require("../Img/Gallery5.png")} alt="icloudsoft_logo" />
                            </div>

                            <div className="col-lg-4 col-md-4 col-sm-6">
                                <img className='w-100' style={{ borderRadius: "30px" }} src={require("../Img/Gallery4.png")} alt="icloudsoft_logo" />
                            </div>

                            <div className="col-lg-4 col-md-4 col-sm-6">
                                <img className='w-100' style={{ borderRadius: "30px" }} src={require("../Img/Gallery3.png")} alt="icloudsoft_logo" />
                            </div>

                            <div className="col-lg-4 col-md-4 col-sm-6">
                                <img className='w-100' style={{ borderRadius: "30px" }} src={require("../Img/Gallery2.png")} alt="icloudsoft_logo" />
                            </div>

                            <div className="col-lg-4 col-md-4 col-sm-6">
                                <img className='w-100' style={{ borderRadius: "30px" }} src={require("../Img/Gallery6.png")} alt="icloudsoft_logo" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid d-sm-none d-none d-md-block" style={{ backgroundImage: `url(${require("../Img/InstagramBg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "contain", height: "1300px", backgroundPosition: 'bottom' }}>
                    <div className="container p-5 text-center" >
                        <div className='d-flex justify-content-center text-center' >
                            <div className='mb-4 mt-4'>
                                <img className='' src={require("../Img/instagram_icon.png")} alt="icloudsoft_logo" />
                            </div>
                            <div className='BirdUpThird '>
                                <div className='BirdDownThird' >
                                    <img className='' src={require("../Img/bird.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>
                        </div>
                        <h3 className='fs-1 playfair-display'>
                            Instagram Feed
                        </h3>
                        <div className="row g-4 d-flex justify-content-around pt-5  align-items-center text-center">
                            <div className="col-xl-3 col-lg-6 col-md-6">
                                <div className='CommonImg'>
                                    <img className='' src={require("../Img/FooterTop1.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-6 col-md-6">
                                <div className='CommonImg mt-5'>
                                    <img className='' src={require("../Img/FooterTop2.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-6 col-md-6">
                                <div className='CommonImg'>
                                    <img className='' src={require("../Img/FooterTop3.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-6 col-md-6">
                                <div className='CommonImg mt-5'>
                                    <img className='' src={require("../Img/FooterTop4.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid d-md-none d-sm-block CoupleImg" style={{ backgroundImage: `url(${require("../Img/InstagramBg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "contain", backgroundPosition: 'bottom' }}>
                    <div className="container p-3 text-center" >
                        <div className='d-flex justify-content-center text-center' >
                            <div className='mb-4 mt-4'>
                                <img className='' src={require("../Img/instagram_icon.png")} alt="icloudsoft_logo" />
                            </div>
                            <div className='BirdUpThird '>
                                <div className='BirdDownThird' >
                                    <img className='' src={require("../Img/bird.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>
                        </div>
                        <h3 className='fs-1 playfair-display'>
                            Instagram Feed
                        </h3>
                        <div className="row g-4 d-flex justify-content-around pt-4  align-items-center text-center">
                            <div className="col-lg-3 col-6">
                                <div className='CommonImg'>
                                    <img className='w-100' src={require("../Img/FooterTop1.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>

                            <div className="col-lg-3 col-6">
                                <div className='CommonImg '>
                                    <img className='w-100' src={require("../Img/FooterTop2.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>

                            <div className="col-lg-3 col-6">
                                <div className='CommonImg'>
                                    <img className='w-100' src={require("../Img/FooterTop3.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>

                            <div className="col-lg-3 col-6">
                                <div className='CommonImg'>
                                    <img className='w-100' src={require("../Img/FooterTop4.png")} alt="icloudsoft_logo" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default Gallery